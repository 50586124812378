import { SpaceType, ComponentTypes } from "../../utils/enums";

export const design_types_data = [
  {
    value: 1,
    label: "Modern",
  },
  {
    value: 2,
    label: "Contemporary",
  },
  {
    value: 3,
    label: "Minimalist",
  },
  {
    value: 4,
    label: "Industrial",
  },
  {
    value: 5,
    label: "Mid-Century Modern",
  },
  {
    value: 6,
    label: "Scandinavian",
  },
  {
    value: 50,
    label: "Post-Modern",
  },
  {
    value: 51,
    label: "Artistic",
  },
  {
    value: 7,
    label: "Traditional",
  },
  {
    value: 8,
    label: "Transitional",
  },
  {
    value: 9,
    label: "Bohemian",
  },
  {
    value: 10,
    label: "Farmhouse",
  },
  {
    value: 11,
    label: "Rustic",
  },
  {
    value: 12,
    label: "Coastal",
  },
  {
    value: 13,
    label: "Shabby Chic",
  },
  {
    value: 14,
    label: "French Country",
  },
  {
    value: 15,
    label: "Art Deco",
  },
  {
    value: 16,
    label: "Hollywood Regency",
  },
  {
    value: 17,
    label: "Japanese Zen",
  },
  {
    value: 18,
    label: "Mediterranean",
  },
  {
    value: 19,
    label: "Victorian",
  },
  {
    value: 20,
    label: "Eclectic",
  },
  {
    value: 21,
    label: "Urban Modern",
  },
  {
    value: 22,
    label: "Classic Georgian",
  },
  {
    value: 23,
    label: "Bauhaus",
  },
  {
    value: 24,
    label: "Colonial",
  },
  {
    value: 25,
    label: "Craftsman",
  },
  {
    value: 26,
    label: "Prairie Style",
  },
  {
    value: 27,
    label: "Art Nouveau",
  },
  {
    value: 28,
    label: "Gothic Revival",
  },
  {
    value: 29,
    label: "Brutalist",
  },
  {
    value: 30,
    label: "Greek Revival",
  },
  {
    value: 31,
    label: "Tuscan",
  },
  {
    value: 32,
    label: "Spanish Revival",
  },
  {
    value: 33,
    label: "Baroque",
  },
  {
    value: 34,
    label: "Rococo",
  },
  {
    value: 35,
    label: "Neoclassical",
  },
  {
    value: 36,
    label: "English Country",
  },
  {
    value: 37,
    label: "Southwest",
  },
  {
    value: 38,
    label: "Tropical",
  },
  {
    value: 39,
    label: "Asian Fusion",
  },
  {
    value: 40,
    label: "Chinese",
  },
  {
    value: 41,
    label: "Japandi",
  },
  {
    value: 42,
    label: "Arabic",
  },

  {
    value: 43,
    label: "Japanese",
  },
  {
    value: 44,
    label: "Persian",
  },
  // {
  // 	"value": 45,
  // 	"label": "Traditional Landscape"
  //   },
  //   {
  // 	"value": 46,
  // 	"label": "Low-Maintenance Xeriscaping"
  //   },
  //   {
  // 	"value": 47,
  // 	"label": "Modern Minimalist"
  //   },
  //   {
  // 	"value": 48,
  // 	"label": "Cottage Garden"
  //   },
  //   {
  // 	"value": 49,
  // 	"label": "Edible Garden"
  //   },
  //   {
  // 	"value": 50,
  // 	"label": "Formal Garden"
  //   },
  //   {
  // 	"value": 51,
  // 	"label": "Japanese Garden"
  //   },
  //   {
  // 	"value": 52,
  // 	"label": "Mediterranean Garden"
  //   },
  //   {
  // 	"value": 53,
  // 	"label": "Native Plant Garden"
  //   },
  //   {
  // 	"value": 54,
  // 	"label": "Rock Garden"
  //   },
  //   {
  // 	"value": 55,
  // 	"label": "Outdoor Living Space"
  //   },
  //   {
  // 	"value": 56,
  // 	"label": "Family-Friendly Lawn"
  //   },
  //   {
  // 	"value": 57,
  // 	"label": "Swimming Pool and Patio"
  //   },
  //   {
  // 	"value": 58,
  // 	"label": "Vegetable and Herb Garden"
  //   },
  //   {
  // 	"value": 59,
  // 	"label": "Zen Garden"
  //   },
  //   {
  // 	"value": 60,
  // 	"label": "Outdoor Kitchen and Dining Area"
  //   },
  //   {
  // 	"value": 61,
  // 	"label": "Wildlife Habitat"
  //   },
  //   {
  // 	"value": 62,
  // 	"label": "Meditation and Yoga Space"
  //   },
  //   {
  // 	"value": 63,
  // 	"label": "Fire Pit and Entertainment Area"
  //   },
  //   {
  // 	"value": 64,
  // 	"label": "English Country Garden"
  //   }
];

export const building_types_data = [
  { value: 1, label: "Villa" },
  { value: 2, label: "House" },
  { value: 3, label: "Condominium" },
  { value: 4, label: "Cottage" },
  { value: 5, label: "Skyscraper" },
  { value: 6, label: "Residential House" },
  { value: 7, label: "Hospital" },
  { value: 8, label: "School" },
  { value: 9, label: "University Campus" },
  { value: 10, label: "Museum" },
  { value: 11, label: "Shopping Mall" },
  { value: 12, label: "Theater" },
  { value: 13, label: "Library" },
  { value: 14, label: "Church" },
  { value: 15, label: "Mosque" },
  { value: 16, label: "Synagogue" },
  { value: 17, label: "Temple" },
  { value: 18, label: "Factory" },
  { value: 19, label: "Warehouse" },
  { value: 20, label: "Sports Stadium" },
  { value: 21, label: "Airport Terminal" },
  { value: 22, label: "Train Station" },
  { value: 23, label: "Fire Station" },
  { value: 24, label: "Courthouse" },
  { value: 25, label: "Hotel" },
  { value: 26, label: "Office Building" },
  { value: 27, label: "Barn" },
  { value: 28, label: "Restaurant" },
];

export const ext_design_types_data = [
  { value: 12, label: "Modern" },
  { value: 14, label: "Futuristic" },
  { value: 39, label: "Scandinavian Modern" },
  { value: 1, label: "Gothic" },
  { value: 40, label: "Japanese Traditional" },
  { value: 41, label: "Post-Modern" },
  { value: 42, label: "Artistic" },
  { value: 2, label: "Baroque" },
  { value: 3, label: "Renaissance" },
  { value: 4, label: "Art Deco" },
  { value: 5, label: "Art Nouveau" },
  { value: 6, label: "Victorian" },
  { value: 7, label: "Bauhaus" },
  { value: 8, label: "Neo-Gothic" },
  { value: 9, label: "Beaux-Arts" },
  { value: 10, label: "Brutalism" },
  { value: 11, label: "Colonial" },
  { value: 13, label: "Postmodern" },
  { value: 15, label: "Craftsman" },
  { value: 16, label: "International Style" },
  { value: 17, label: "Deconstructivism" },
  { value: 18, label: "High-Tech Architecture" },
  { value: 19, label: "Streamline Moderne" },
  { value: 20, label: "Romanesque" },
  { value: 21, label: "Byzantine" },
  { value: 22, label: "Prairie Style" },
  { value: 23, label: "Mediterranean" },
  { value: 24, label: "Tudor" },
  { value: 25, label: "Georgian" },
  { value: 26, label: "Palladian" },
  { value: 27, label: "Federal" },
  { value: 28, label: "Art Moderne" },
  { value: 29, label: "Egyptian Revival" },
  { value: 30, label: "Carpenter Gothic" },
  { value: 31, label: "Rococo" },
  { value: 32, label: "Aesthetic Movement" },
  { value: 33, label: "American Foursquare" },
  { value: 34, label: "Italianate" },
  { value: 35, label: "Second Empire" },
  { value: 36, label: "Stick Style" },
  { value: 37, label: "Spanish Architecture" },
  { value: 38, label: "Greco-Roman" },

];

export const room_types_data = [
  {
    value: 1,
    label: "Living Room",
  },
  {
    value: 2,
    label: "Bedroom",
  },
  {
    value: 3,
    label: "Kitchen",
  },
  {
    value: 4,
    label: "Bathroom",
  },
  {
    value: 65,
    label: "Washroom",
  },
  {
    value: 5,
    label: "Home Office",
  },
  {
    value: 6,
    label: "Dining Room",
  },
  {
    value: 7,
    label: "Children's Room",
  },
  {
    value: 8,
    label: "Cafe",
  },
  {
    value: 9,
    label: "Restaurant",
  },
  {
    value: 10,
    label: "Bar",
  },
  {
    value: 11,
    label: "Hotel Lobby",
  },
  {
    value: 12,
    label: "Home Theater",
  },
  {
    value: 13,
    label: "Library",
  },
  {
    value: 14,
    label: "Retail Store",
  },
  {
    value: 15,
    label: "Art Gallery",
  },
  {
    value: 16,
    label: "Gym",
  },
  {
    value: 17,
    label: "Spa",
  },
  {
    value: 18,
    label: "Game Room",
  },
  {
    value: 19,
    label: "Wine Cellar",
  },
  {
    value: 20,
    label: "Studio Apartment",
  },
  {
    value: 21,
    label: "Loft",
  },
  {
    value: 22,
    label: "Sunroom",
  },
  {
    value: 23,
    label: "Mudroom",
  },
  {
    value: 24,
    label: "Laundry Room",
  },
  {
    value: 25,
    label: "Garage",
  },
  {
    value: 26,
    label: "Basement",
  },
  {
    value: 27,
    label: "Rooftop Lounge",
  },
  {
    value: 28,
    label: "Greenhouse",
  },
  {
    value: 29,
    label: "Hair Salon",
  },
  {
    value: 30,
    label: "Airport Lounge",
  },
  {
    value: 31,
    label: "School Classroom",
  },
  {
    value: 32,
    label: "Yoga Studio",
  },
  {
    value: 33,
    label: "Hospital Room",
  },
  {
    value: 34,
    label: "Movie Theater",
  },
  // {
  // 	"value": 35,
  // 	"label": "Train Station Waiting Room"
  // },
  {
    value: 36,
    label: "Bank",
  },
  {
    value: 37,
    label: "Conference Room",
  },
  {
    value: 38,
    label: "Co-working Space",
  },
  {
    value: 39,
    label: "Dance Studio",
  },
  {
    value: 40,
    label: "Bakery",
  },
  {
    value: 41,
    label: "Nursery",
  },
  {
    value: 42,
    label: "Guest Bedroom",
  },
  {
    value: 43,
    label: "Home Bar",
  },
  {
    value: 44,
    label: "Walk-in Closet",
  },
  {
    value: 45,
    label: "Reception Area",
  },
  {
    value: 46,
    label: "Recording Studio",
  },
  {
    value: 47,
    label: "Meditation Room",
  },
  {
    value: 48,
    label: "Food Court",
  },
  {
    value: 49,
    label: "Fast Food Restaurant",
  },
  {
    value: 50,
    label: "Nightclub",
  },
  {
    value: 51,
    label: "Outdoor Patio",
  },
  {
    value: 52,
    label: "Boutique",
  },
  {
    value: 53,
    label: "Bookstore",
  },
  {
    value: 54,
    label: "Pharmacy",
  },
  {
    value: 55,
    label: "Waiting Room",
  },
  {
    value: 56,
    label: "Fitness Center",
  },
  {
    value: 57,
    label: "Garden Shed",
  },
  {
    value: 58,
    label: "Pool House",
  },
  {
    value: 59,
    label: "Workshop",
  },
  {
    value: 60,
    label: "College Dorm Room",
  },
  {
    value: 61,
    label: "Office",
  },
  {
    value: 62,
    label: "Playroom",
  },
  // {
  // 	"value": 63,
  // 	"label": 'Front Lawn'
  // },
  // {
  // 	"value": 64,
  // 	"label": 'Backyard'
  // }
];

export const model_types_data = [
  {
    value: 0,
    label: "AI Apprentice",
    shortLabel: "Apprentice",
    modelType: ComponentTypes.MODEL_1,
    display: false,
    displayDebug: false
  },
  {
    value: 1,
    label: "AI Navigator",
    shortLabel: "Navigator",
    modelType: ComponentTypes.MODEL_2,
    display: true,
    displayDebug: true
  },
  {
    value: 2,
    label: "AI Saga",
    shortLabel: "Saga",
    modelType: ComponentTypes.MODEL_3,
    display: true,
    displayDebug: true
  },
  {
    value: 3,
    label: "Magic Edit",
    shortLabel: "Magic Edit",
    modelType: ComponentTypes.MODEL_4,
    display: false,
    displayDebug: false
  },
  {
    value: 6,
    label: "Refiner",
    shortLabel: "Refiner",
    modelType: ComponentTypes.MODEL_6,
    display: false,
    displayDebug: false
  },
  {
    value: 7,
    label: "UPSCALE",
    shortLabel: "UPSCALE",
    modelType: ComponentTypes.MODEL_7,
    display: false,
    displayDebug: false
  },
  {
    value: 8,
    label: "V2",
    shortLabel: "V2",
    modelType: ComponentTypes.MODEL_8,
    display: false,
    displayDebug: true
  }
];

export const visibility_types_data = [
  {
    value: 0,
    label: "Public",
    image: "",
  },
  {
    value: 1,
    label: "Private",
    image: "",
  },
];

export function setValueFromName(baseData, label) {
  let returnItem = null;
  if (!label) {
    return baseData[0];
  }

  baseData.forEach((item) => {
    if (item["label"] == label) {
      returnItem = item;
    }
  });

  if (returnItem) {
    return returnItem;
  }

  // console.log("dataset", baseData);
  // console.log("Nothing found for value ", label);
}

export const color_palettes = [
  {
    value: 0,
    colors: ["#4e4a24", "#2c2a2b", "#d3aa7e", "#955529"],
  },
  {
    value: 1,
    colors: ["#a8704b", "#2f2b28", "#b26f66", "#d3ab92"],
  },
  {
    value: 2,
    colors: ["#75767a", "#f5b880", "#9f7678", "#e8eae9"],
  },
  {
    value: 3,
    colors: ["#c8d1da", "#b8baac", "#ca9c78", "#6d6e73"],
  },
  {
    value: 4,
    colors: ["#787c85", "#cdbdae", "#41607f", "#afafb7"],
  },
  {
    value: 5,
    colors: ["#03789b", "#cb7400", "#cab188", "#224b45"],
  },
  {
    value: -1,
    colors: ["#5d5d5d", "#5d5d5d", "#5d5d5d", "#5d5d5d"],
  },
];

export const test_data = ["#5d5d5d", "#5d5d5d", "#5d5d5d", "#5d5d5d"]

/*
	"Style: Enhance","breathtaking {prompt} . award-winning, professional, highly detailed","ugly, deformed, noisy, blurry, distorted, grainy"
	"Style: Anime","anime artwork {prompt} . anime style, key visual, vibrant, studio anime, highly detailed","photo, deformed, black and white, realism, disfigured, low contrast"
	"Style: Photographic","cinematic photo {prompt} . 35mm photograph, film, bokeh, professional, 4k, highly detailed","drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly"
	"Style: Digital art","concept art {prompt} . digital artwork, illustrative, painterly, matte painting, highly detailed","photo, photorealistic, realism, ugly"
	"Style: Comic book","comic {prompt} . graphic illustration, comic art, graphic novel art, vibrant, highly detailed","photograph, deformed, glitch, noisy, realistic, stock photo"
	"Style: Fantasy art","ethereal fantasy concept art of {prompt} . magnificent, celestial, ethereal, painterly, epic, majestic, magical, fantasy art, cover art, dreamy","photographic, realistic, realism, 35mm film, dslr, cropped, frame, text, deformed, glitch, noise, noisy, off-center, deformed, cross-eyed, closed eyes, bad anatomy, ugly, disfigured, sloppy, duplicate, mutated, black and white"
	"Style: Analog film","analog film photo {prompt} . faded film, desaturated, 35mm photo, grainy, vignette, vintage, Kodachrome, Lomography, stained, highly detailed, found footage","painting, drawing, illustration, glitch, deformed, mutated, cross-eyed, ugly, disfigured"
	"Style: Neonpunk","neonpunk style {prompt} . cyberpunk, vaporwave, neon, vibes, vibrant, stunningly beautiful, crisp, detailed, sleek, ultramodern, magenta highlights, dark purple shadows, high contrast, cinematic, ultra detailed, intricate, professional","painting, drawing, illustration, glitch, deformed, mutated, cross-eyed, ugly, disfigured"
	"Style: Isometric","isometric style {prompt} . vibrant, beautiful, crisp, detailed, ultra detailed, intricate","deformed, mutated, ugly, disfigured, blur, blurry, noise, noisy, realistic, photographic"
	"Style: Lowpoly","low-poly style {prompt} . low-poly game art, polygon mesh, jagged, blocky, wireframe edges, centere d composition","noisy, sloppy, messy, grainy, highly detailed, ultra textured, photo"
	"Style: Origami","origami style {prompt} . paper art, pleated paper, folded, origami art, pleats, cut and fold, centered composition","noisy, sloppy, messy, grainy, highly detailed, ultra textured, photo"
	"Style: Line art","line art drawing {prompt} . professional, sleek, modern, minimalist, graphic, line art, vector graphics","anime, photorealistic, 35mm film, deformed, glitch, blurry, noisy, off-center, deformed, cross-eyed, closed eyes, bad anatomy, ugly, disfigured, mutated, realism, realistic, impressionism, expressionism, oil, acrylic"
	"Style: Craft clay","play-doh style {prompt} . sculpture, clay art, centered composition, Claymation","sloppy, messy, grainy, highly detailed, ultra textured, photo"
	"Style: Cinematic","cinematic film still {prompt} . shallow depth of field, vignette, highly detailed, high budget Hollywood movie, bokeh, cinemascope, moody, epic, gorgeous, film grain, grainy","anime, cartoon, graphic, text, painting, crayon, graphite, abstract, glitch, deformed, mutated, ugly, disfigured"
	"Style: 3d-model","professional 3d model {prompt} . octane render, highly detailed, volumetric, dramatic lighting","ugly, deformed, noisy, low poly, blurry, painting"
	"Style: pixel art","pixel-art {prompt} . low-res, blocky, pixel art style, 8-bit graphics","sloppy, messy, blurry, noisy, highly detailed, ultra textured, photo, realistic"
	"Style: Texture","texture {prompt} top down close-up","ugly, deformed, noisy, blurry"
*/

export const baseImages = [
  {
    id: 0,
    url: "/images/engine/exterior_model_1_small.jpg",
    large_url:
      "https://davinte.nyc3.cdn.digitaloceanspaces.com/assets/shared_images/exterior_model_1.jpg",
    type: SpaceType.EXTERIOR,
    space_type: building_types_data[1],
    design_type: ext_design_types_data[0],
  },
  {
    id: 1,
    url: "/images/engine/exterior_model_2_small.jpg",
    large_url:
      "https://davinte.nyc3.cdn.digitaloceanspaces.com/assets/shared_images/exterior_model_2.jpg",
    type: SpaceType.EXTERIOR,
    space_type: building_types_data[0],
    design_type: ext_design_types_data[0],
  },
  {
    id: 2,
    url: "/images/engine/interior_model_1_small.jpg",
    large_url:
      "https://davinte.nyc3.cdn.digitaloceanspaces.com/assets/shared_images/interior_model_1.jpg",
    type: SpaceType.INTERIOR,
    space_type: room_types_data[0],
    design_type: design_types_data[0],
  },
  {
    id: 3,
    url: "/images/engine/interior_model_2_small.jpg",
    large_url:
      "https://davinte.nyc3.cdn.digitaloceanspaces.com/assets/shared_images/interior_model_2.jpg",
    type: SpaceType.INTERIOR,
    space_type: room_types_data[3],
    design_type: design_types_data[2],
  },
];

export const condition_keys = {
  [SpaceType.EXTERIOR]: [ "SEASON", "CLIMATE", "TIME_OF_DAY", "WEATHER", "LANDSCAPE"],
  [SpaceType.INTERIOR]: [ "LIGHTING_CONDITIONS", "TIME_OF_DAY_INT"]
}

export const conditionsList = {
  WEATHER: ["Rainy", "Stormy", "Snowfall", "Sunny", "Overcast", "Foggy", "Clear"],
  TIME_OF_DAY: [
    "Morning",
    "Afternoon",
    "Evening",
    "Night",
    "Twilight",
    "Dawn",
    "Dusk",
    "Midday"    
  ],
  CLIMATE: ["Humid", "Dry", "Tropical"],
  LANDSCAPE: [
    "Mountainous",
    "Coastal",
    "Rural",
    "Forest",
    "Desert",
    "Riverbank",
    "Lakeside",
    // "Wetlands",
    "Prairie",
    "Canyon",
    // "Savanna",
    "Rainforest",
  ],
  SEASON: ["Spring", "Summer", "Winter", "Fall"],
  // COMFORT_LEVELS: ["Cozy", "Spacious", "Compact", "Luxurious"],
  TIME_OF_DAY_INT: ["Morning", "Afternoon", "Evening", "Night"],
  LIGHTING_CONDITIONS: ["Bright", "Soft", "Ambient", "Natural", "Artificial", "Warm", "Cool"]
};


export const conditionsValues = {
  WEATHER: {
    Rainy: { name: "Rainy", iconClass: "fad fa-cloud-rain", iconImageSrc: "" },
    Stormy: { name: "Stormy", iconClass: "fad fa-thunderstorm", iconImageSrc: "" },
    Snowfall: { name: "Snowfall", iconClass: "fad fa-snowflakes", iconImageSrc: "" },
    Sunny: { name: "Sunny", iconClass: "fad fa-sun", iconImageSrc: "" },
    Windy: { name: "Windy", iconClass: "fad fa-wind", iconImageSrc: "" },
    Overcast: { name: "Overcast", iconClass: "fas fa-clouds", iconImageSrc: "" },
    Foggy: { name: "Foggy", iconClass: "fad fa-sun-dust", iconImageSrc: "" },
    Clear: { name: "Clear", iconClass: "fad fa-moon-stars", iconImageSrc: "" },
  },
  TIME_OF_DAY: {
    Morning: { name: "Morning", iconClass: "fad fa-coffee", iconImageSrc: "" },
    Afternoon: { name: "Afternoon", iconClass: "fad fa-sun", iconImageSrc: "" },
    Evening: { name: "Evening", iconClass: "fad fa-sunset", iconImageSrc: "" },
    Night: { name: "Night", iconClass: "fad fa-moon", iconImageSrc: "" },
    Twilight: { name: "Twilight", iconClass: "fad fa-stars", iconImageSrc: "" },
    Dawn: { name: "Dawn", iconClass: "fad fa-sunrise", iconImageSrc: "" },
    Dusk: { name: "Dusk", iconClass: "fad fa-sun-haze", iconImageSrc: "" },
    Midday: { name: "Midday", iconClass: "fad fa-sunglasses", iconImageSrc: "" },
  },
  CLIMATE: {
    Humid: { name: "Humid", iconClass: "fad fa-humidity", iconImageSrc: "" },
    Dry: { name: "Dry", iconClass: "fad fa-skull-cow", iconImageSrc: "" },
    Tropical: { name: "Tropical", iconClass: "fad fa-island-tropical", iconImageSrc: "" },
  },
  LANDSCAPE: {
    Desert: { name: "Desert", iconClass: "fad fa-cactus", iconImageSrc: "" },
    Mountainous: { name: "Mountainous", iconClass: "fad fa-mountains", iconImageSrc: "" },
    Coastal: { name: "Coastal", iconClass: "fad fa-water", iconImageSrc: "" },
    Urban: { name: "Urban", iconClass: "fad fa-city", iconImageSrc: "" },
    Rural: { name: "Rural", iconClass: "fad fa-house", iconImageSrc: "" },
    Forest: { name: "Forest", iconClass: "fad fa-tree-large", iconImageSrc: "" },
    Riverbank: { name: "Riverbank", iconClass: "far fa-house-flood", iconImageSrc: "" },
    Lakeside: { name: "Lakeside", iconClass: "fad fa-ship", iconImageSrc: "" },
    Prairie: { name: "Prairie", iconClass: "fad fa-wheat", iconImageSrc: "" },
    Canyon: { name: "Canyon", iconClass: "fad fa-volcano", iconImageSrc: "" },
    Rainforest: { name: "Rainforest", iconClass: "fad fa-trees", iconImageSrc: "" },
  },
  SEASON: {
    Spring: { name: "Spring", iconClass: "fad fa-flower-tulip", iconImageSrc: "" },
    Summer: { name: "Summer", iconClass: "fad fa-umbrella-beach", iconImageSrc: "" },
    Winter: { name: "Winter", iconClass: "fad fa-snowman", iconImageSrc: "" },
    Fall: { name: "Fall", iconClass: "fad fa-leaf-maple", iconImageSrc: "" },
  },
  // COMFORT_LEVELS: {
  //   Cozy: {name: "Cozy", iconClass: "fad fa-couch", iconImageSrc: "" },
  //   Spacious: {name: "Spacious", iconClass: "fad fa-expand-wide", iconImageSrc: "" },
  //   Compact: {name: "Compact", iconClass: "fad fa-expand", iconImageSrc: "" },
  //   Luxurious: {name: "Luxurious", iconClass: "fad fa-glass-champagne", iconImageSrc: "" },
  // },
  TIME_OF_DAY_INT: {
    Morning: {name: "Morning", iconClass: "fad fa-coffee", iconImageSrc: "" },
    Afternoon: {name: "Afternoon", iconClass: "fad fa-sun", iconImageSrc: "" },
    Evening: { name: "Evening", iconClass: "fad fa-sunset", iconImageSrc: "" },
    Night: {name: "Night", iconClass: "fad fa-moon", iconImageSrc: "" },
  },
  LIGHTING_CONDITIONS: {
    Bright: {name: "Bright", iconClass: "fad fa-lightbulb-on", iconImageSrc: "" },
    Soft: {name: "Soft", iconClass: "fad fa-lightbulb", iconImageSrc: "" },
    Ambient:{name: "Ambient", iconClass: "fad fa-flashlight", iconImageSrc: "" },
    Natural: {name: "Natural", iconClass: "fad fa-sun", iconImageSrc: "" },
    Artificial: {name: "Artificial", iconClass: "fas fa-light-ceiling", iconImageSrc: "" },
    Warm: {name: "Warm", iconClass: "fal fa-fire-alt", iconImageSrc: "" },
    Cool: {name: "Cool", iconClass: "fad fa-temperature-frigid", iconImageSrc: "" },
    Dim: {name: "Dim", iconClass: "fad fa-lightbulb", iconImageSrc: "" },
  },
};

// LANDSCAPE:
// // Wetlands: { name: "Wetlands", iconClass: "", iconImageSrc: "" },
// // Savanna: { name: "Savanna", iconClass: "", iconImageSrc: "" },