import React, { useState, useEffect } from "react";
import FileUpload from "../../uploader/fileUpload";
import Grid from "@mui/material/Grid";
import { CustomSlider, marks } from "../../slider/customSlider";
import { CoreDesignPosition, SpaceType, ConditionTypes } from "../../../utils/enums";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional


export default function Styler(props) {
  const {
    control,
    files,
    setFiles,
    errors,
    clearErrors,
    form_name,
    setValue,
    onImageUploadSelect,
    onImageUploadUnselect,
    panelPosition,
    unregister,
    strengthData,
    design
  } = props;

  const [strength, setStrength] = useState(strengthData? strengthData : 80);
  const [styleImage, setStyleImage] = useState(null);

  useEffect(() => {
    if (design && design.otherConfig) {
        const otherConfig = JSON.parse(design.otherConfig);
        if (otherConfig && otherConfig["STYLER"] && otherConfig["STYLER"]["REF_IMAGE"]) {
            setStyleImage(otherConfig["STYLER"]["REF_IMAGE"]);
            setStrength(otherConfig["STYLER"]["SCALE"]? otherConfig["STYLER"]["SCALE"] * 100 : 75)
        }
    }
  }, [design]);

  useEffect(() => {
    if (styleImage) {
        setValue(form_name, styleImage);
    }
    else {
        // unregister(form_name);
        setValue(form_name, null);
    }
  }, [styleImage]);

  useEffect(() => {
    setValue("styler_strength", strength);
  }, [strength]);

  return (
    <div className="w-full h-fit flex justify-center rounded-lg border border-dashed dark:border-jacarta-600 border-jacarta-100">
      <div className={`${panelPosition && panelPosition === CoreDesignPosition.SIDE? "w-32" : "w-32 md:w-40"}`}>
        {!styleImage? (<FileUpload
          files={files}
          setFiles={setFiles}
          errors={errors}
          clearErrors={clearErrors}
          form_name={form_name}
          control={control}
          required={false}
          setValue={setValue}
          onImageUploadSelect={onImageUploadSelect}
          errorMessage={
            "Upload an image or provide a prompt - you CAN do both as well"
          }
          heightClass={"h-24"}
          onImageUploadUnselect={onImageUploadUnselect}
        />) : (<div className="w-full flex flex-col justify-center p-3 relative h-24">
            <img
                src={styleImage}
                className="dark:border-jacarta-600 border-jacarta-100 border-1 object-cover w-full h-full rounded"
                // onClick={() => {
                //     setFiles([]);
                //     setStyleImage(null);
                // }}
            />
            <div className="absolute right-4 top-4 p-1 h-7 w-7 flex flex-row-reverse justify-center items-center text-center z-10 bg-gray-600 hover:bg-opacity-90 dark:hover:bg-opacity-90 bg-opacity-50 dark:bg-opacity-50 rounded-md">
                <button
                type="button"
                className="btn-close-relative flex justify-center items-center"
                onClick={() => {
                    setFiles([]);
                    setStyleImage(null);
                }}
                >
                <i className="fal fa-times fill-white text-white hover:text-accent-light text-md px-2"></i>
                </button>
            </div>
          </div>)}
      </div>
      <div className="flex-grow flex flex-col justify-center relative">
        <span className="pb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex w-fit justify-start">
            Strength <Tippy
            interactive={true}
            interactiveBorder={20}
            delay={100}
            content={
              <div className="px-5 py-3">
                <span className="normal-case">
                    This indicates how much the generated image should be inspired by the reference image. 100 is the highest strength.
                </span>
              </div>
            }
          >
            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
              <b>?</b>
            </span>
          </Tippy>
        </span>
        <div className="md:pr-5 pl-1 w-full flex flex-col justify-center">
            <CustomSlider
                aria-label="Always visible"
                value={strength}
                min={0}
                step={1}
                max={100}
                marks={marks}
                disabled={(!files || files.length === 0) && (!styleImage)}
                valueLabelDisplay="auto"
                onChange={(event, newValue) => {
                    setStrength(newValue);
                }}
                color="secondary"/>
        </div>
        {(!files || files.length === 0) && (!styleImage) && (
            <Tippy
                interactive={true}
                interactiveBorder={20}
                delay={100}
                content={<div className="px-5 py-3">
                    <span className="normal-case">
                        Upload a style image to configure the the strength of the style transfer.
                    </span>
                </div>}>
                    <div className="w-full h-full absolute z-1 dark:bg-jacarta-600 bg-jacarta-600 top-0 left-0 dark:bg-opacity-70 bg-opacity-70 rounded-r-lg">

                    </div>
            </Tippy>
        )}
      </div>
    </div>
  );
}
