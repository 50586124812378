import React, { useState, useEffect, useRef, use } from "react";
import { CoreDesignPosition, ConditionTypes } from "../../../utils/enums";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import ColorPalettes from "../../color_palette/colorPalettes";
import ConditionNode from "./conditions";
import Accordion from "../../modal/accordion";

export default function AdvancedSettings(props) {
  const {
    colorPalette,
    setColorPalette,
    panelPosition,
    colorPatternDisabled,
    modelId,
  } = props;

  function getColorPaletteTitle() {
    return (
      <div className="flex w-fit justify-start flex-col">
        <div className="flex w-fit justify-start">
          Color Palette
          <Tippy
            interactive={true}
            interactiveBorder={20}
            delay={100}
            content={
              <div className="px-5 py-3">
                <ul>
                  <li className="normal-case">Color Palette is a set of colors that</li>
                  <li className="normal-case">will be used to generate the color pattern.</li>
                  <li className="normal-case">
                    <a
                      className="text-accent-lighter"
                      href="/blogs/posts/color_palette"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click to learn more.
                    </a>
                  </li>
                </ul>
                {colorPatternDisabled && (
                  <span className="text-xs">
                    (
                    <b className="text-red">
                      {modelId == "3" ? "Magic Edit" : "Apprentice model"}{" "}
                    </b>{" "}
                    doesn&apos;t support Color Palette, yet!)
                    <br />
                    (Only <b className="text-red">Navigator</b> and{" "}
                    <b className="text-red">Saga</b> support Color Palette,
                    today!)
                    <br />
                    Upgrade or change the model type
                  </span>
                )}
              </div>
            }
          >
            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
              <b>?</b>
            </span>
          </Tippy>
        </div>
        {colorPatternDisabled && (
          <span className="text-xs">
            <b className="text-red">
              {modelId == "3" ? "Magic Edit" : "Apprentice model"}
            </b>{" "}
            doesn&apos;t support Color Palette, yet!
            <br />
            Only <b className="text-red">Navigator</b> and{" "}
            <b className="text-red">Saga</b> support Color Palette, today!
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="pb-2 hidden">
      <Accordion title={getColorPaletteTitle()}>
          <div className="w-full flex justify-center flex-col">
            <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex justify-start flex-col md:flex-row"></span>
            <div className="w-full flex flex-col justify-center items-center">
              <Tippy
                interactive={true}
                disabled={!colorPatternDisabled}
                interactiveBorder={20}
                delay={100}
                content={
                  <div className="px-5 py-3">
                    <span className="text-xs">
                      <b className="text-red">
                        {modelId == "3" ? "Magic Edit" : "Apprentice model"}
                      </b>{" "}
                      doesn&apos;t support Color Palette, yet!
                      <br />
                      Only <b className="text-red">Navigator</b> and{" "}
                      <b className="text-red">Saga</b> support Color Palette,
                      today!
                    </span>
                  </div>
                }
              >
                <div className="w-full px-1">
                  <ColorPalettes
                    colorPalette={colorPalette}
                    setColorPalette={setColorPalette}
                    colorPatternDisabled={colorPatternDisabled}
                  />
                </div>
              </Tippy>
            </div>
          </div>
      </Accordion>
    </div>
  );
}
