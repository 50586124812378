import React, { useState, useEffect, useRef, use } from "react";
import { CoreDesignPosition } from "../../utils/enums";
import {
  conditionsList,
  conditionsValues,
} from "../../data/model_configs/interface_data";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional


export default function Accordion(props) {
  const { title, children, bordered, halfSize, defaultOpen, dynamicEvent, tooltip, leftMargin = "ml-2", showCollapseFar=true } = props;
  const [open, setOpen] = useState(defaultOpen);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  function handleResize() {
    if (!contentRef || !contentRef.current || !contentRef.current.scrollHeight) {
      return;
    }

    const contentElement = contentRef.current;
    const updateHeight = () => {
      setContentHeight(open ? contentElement.scrollHeight : 0);
    };

    const resizeObserver = new ResizeObserver(() => {
      if (open) updateHeight();
    });

    if (contentElement) {
      resizeObserver.observe(contentElement);
      updateHeight(); // Initial update
    }

    return () => {
      if (contentElement) {
        resizeObserver.unobserve(contentElement);
      }
    };
  }

  useEffect(() => {
    handleResize();
  }, [dynamicEvent, contentRef, open]);

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen]);

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center">
        <div className={`w-full flex ${showCollapseFar? "justify-between" : "justify-start" } cursor-pointer py-2`} onClick={() => setOpen(prev => !prev)}>
            <span className={`${leftMargin} mb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex justify-start capitalize`}>{ title } {tooltip && tooltip}</span>
            {/* <Tippy
              interactive={true}
              interactiveBorder={20}
              delay={100}
              content={
                  <div className="px-5 py-3 w-full flex flex-col justify-center">
                      <span className="normal-case">
                          {open? "Close" : "Open"} {title}
                      </span>
                  </div>
              }
              >
            </Tippy> */}
              <span className={`ml-2 mb-1 text-sm text-accent flex justify-start ${open? "fas fa-chevron-double-up" : "fas fa-chevron-double-down"}`}></span>
        </div>
      <div className={`overflow-hidden transition-[height] duration-500 ease-in-out w-full`} 
            style={{ height: open ? `${contentHeight + 5}px` : `${halfSize? halfSize : "0"}px` }}>
        <div ref={contentRef} className={`${bordered? "dark:border-1 border-1 dark:border-jacarta-700 border-jacarta-100 px-3" : ""} w-full`}>
            {
                children
            }
        </div>
      </div>
    </div>
  );
}
