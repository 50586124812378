import React from 'react';
import Slider from '@mui/material/Slider';
import { styled, } from '@mui/material/styles';
// import { primaryColor, secondaryColor } from '../../lib/utils'
import tailwindConfig from '../../tailwind.config';
import { useTheme } from "next-themes";

const boxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const CustomSlider = styled(Slider)(({ theme }) => {
    const { theme: globalTheme, setTheme } = useTheme();
    return ({
        color: globalTheme === 'dark' ? tailwindConfig.theme.colors.jacarta['50'] : tailwindConfig.theme.colors.jacarta['800'],
        height: 2,
        padding: '15px 0',
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            boxShadow: boxShadow,
            '&:focus, &:hover, &.Mui-active': {
                boxShadow:
                    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    boxShadow: boxShadow,
                },
            },
        },
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-rail': {
            opacity: 0.75,
            backgroundColor: '',
        },
        '& .MuiSlider-mark': {
            backgroundColor: '',
            height: 8,
            width: 1,
            '&.MuiSlider-markActive': {
                opacity: 1,
                backgroundColor: 'currentColor',
            },
        },
        '& .MuiSlider-markLabel': {
            color: globalTheme === 'dark' ? tailwindConfig.theme.colors.jacarta['50'] : tailwindConfig.theme.colors.jacarta['400'],
        },
    })
});

/*export function CustomSlider({ value, min, step, max, valueLabelDisplay, onChange }) {
    const classes = {
        root: {
          width: 300,
          margin: 'auto',
        },
        customThumb: {
          height: 24,
          width: 24,
          backgroundColor: '#FFF',
          border: '2px solid currentColor',
          marginTop: -8,
          marginLeft: -12,
          '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
          },
        },
        customTrack: {
          height: 8,
          borderRadius: 4,
        },
        customRail: {
          height: 8,
          borderRadius: 4,
          opacity: 0.3,
          backgroundColor: 'currentColor',
        },
      };
    return (
        <Slider
            value={value}
            min={min}
            step={step}
            max={max}
            valueLabelDisplay={valueLabelDisplay}
            onChange={onChange}
            defaultValue={30}
            components={{
                Thumb: React.forwardRef((props, ref) => {
                    console.log('props', props);
                    return (
                    <span
                        {...props}
                        ref={ref}
                        className="w-6 h-6 dark:bg-white shadow-base bg-accent border border-current rounded-full"
                    />
                )}),
                Track: React.forwardRef((props, ref) => (
                    <span {...props} ref={ref} className={classes.customTrack} />
                )),
                Rail: React.forwardRef((props, ref) => (
                    <span {...props} ref={ref} className={classes.customRail} />
                )),
            }}
        />
    )
}*/

/*-mt-2 -ml-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-accent*/

export const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 25,
      label: "25",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 75,
      label: "75",
    },
    {
      value: 100,
      label: "100",
    },
  ];