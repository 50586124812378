import React, { useState, useEffect, useRef } from "react";
import {
    color_palettes
} from "../../data/model_configs/interface_data";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ColorPicker from './colorPicker';
import Grid from '@mui/material/Grid';
import { CustomSlider } from '../slider/customSlider'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { min } from "moment";
import { designFeatureAccessControl } from '../../utils/managedSub';
import { ComponentTypes } from '../../utils/enums';
import { useSession } from "next-auth/react";
import { useSelector, useDispatch } from "react-redux";
import { plansModalShow } from '../../redux/counterSlice';

export default function ColorPalettes(props) {
    const dispatch = useDispatch();
    const { colorPalette, setColorPalette, colorPatternDisabled } = props;

    const [colorScale, setColorScale] = useState(colorPalette && colorPalette.color_scale ? colorPalette.color_scale : 100);
    const [customColors, setCustomColors] = useState(color_palettes.find(item => item.value === -1).colors); // colorPalette && colorPalette['colors'] && colorPalette['colors'].length > 0? colorPalette['colors'] : []); // color_palettes.find(item => item.value === -1).colors

    const noSelection = { 'value': -2, 'colors': [], 'color_scale': 75 }

    const { data: session } = useSession();
    const { subStatus } = useSelector((state) => state.counter);

    const handleChange = (color_palette) => {
        if (!color_palette || (colorPatternDisabled && color_palette.value !== -2))
            return;

        // console.log('handleChange clicked:', color_palette.colors)
        setColorPalette({ 'value': color_palette.value, 'colors': color_palette.colors, 'color_scale': colorScale });
    };

    useEffect(() => {
        // console.log('colorPalette changed', colorPalette)
        if (colorPalette && colorPalette.value === -1) {
            setCustomColors(colorPalette.colors);
        }

        if (colorPalette && colorPalette.color_scale) {
            setColorScale(colorPalette.color_scale);
        }
    }, [colorPalette, setColorPalette])

    useEffect(() => {
        // console.log('disabled changed to', colorPatternDisabled)
        if (colorPatternDisabled) {
            handleChange(noSelection);
        }
    }, [colorPatternDisabled])

    // useEffect(() => {
    //     if (colorPalette && colorPalette.color_scale) {
    //         setColorPalette(prev => ({ ...prev, 'color_scale' : colorScale }));
    //     }
    // }, [colorScale, setColorScale]);

    function onScaleChange(sScale) {
        setColorScale(sScale)
        if (colorPalette && colorPalette.color_scale) {
            setColorPalette(prev => ({ ...prev, 'color_scale': sScale }));
        }
    }

    function setCustomerColor(index, color) {
        let newColorPalette = colorPalette;
        if ('colors' in newColorPalette && newColorPalette.colors && newColorPalette.colors.length > 0)
            newColorPalette.colors[index] = color;

        setColorPalette(newColorPalette);
    }

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 25,
            label: '25',
        },
        {
            value: 50,
            label: '50',
        },
        {
            value: 75,
            label: '75',
        },
        {
            value: 100,
            label: '100',
        },
    ];

    const customColorSupported = designFeatureAccessControl(session, subStatus, ComponentTypes.CUSTOM_COLORS);
    // console.log('customColorSupported', customColorSupported)

    return (
        <div className="border-2 rounded-lg dark:border-jacarta-700 border-jacarta-100 items-center border-dashed mt-1 relative overflow-hidden">
            <div className={`flex justify-between items-center absolute w-full h-full dark:bg-jacarta-600 bg-jacarta-300 bg-opacity-50 dark:bg-opacity-50 ${colorPatternDisabled ? 'block' : 'hidden'}`} style={{ zIndex: 2 }}>

            </div>
            <div className="p-1 rounded-lg dark:border-jacarta-700 border-jacarta-100 items-center border-dashed mt-1 relative">
                <div className="w-full">
                    <div onClick={() => handleChange(noSelection)} className={`${colorPalette && colorPalette.value == -2 ? 'bg-jacarta-50 dark:bg-jacarta-700' : ''} cursor-pointer hover:bg-jacarta-100 dark:hover:bg-jacarta-600 rounded-md h-11`}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={colorPalette.value}
                        >
                            <div className="grid grid-cols-9 h-full">
                                <div className="w-full h-full flex justify-center items-center">
                                    <Radio
                                        value={-2}
                                        sx={{
                                            color: '#8358FF',
                                            '&.Mui-checked': {
                                                color: '#8358FF',
                                            },
                                            // '&.MuiFormControlLabel-root': {
                                            //     marginLeft: '0px !important',
                                            //     marginRight: '0px !important',
                                            // }
                                        }}
                                    />
                                    {/* <FormControlLabel  control={} /> */}
                                </div>
                                <div className="w-full h-full col-span-8 p-1 ">
                                    <div className="flex justify-start border p-1 rounded-md dark:border-jacarta-700 border-jacarta-100 items-center">
                                        <span className="text-sm p-1">No color pattern</span>
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </div>
                    {
                        color_palettes.map((color_palette, index_main) => {
                            return (
                                <Tippy key={index_main} interactive={true} disabled={color_palette.value != -1 || customColorSupported} interactiveBorder={20} delay={100} content={<div className="px-5 py-3"><ul><li>To unlock custom colors</li><li>you need to upgrade to the</li><li><span className="font-display">Premium Plans</span></li><li onClick={() => dispatch(plansModalShow())} className="cursor-pointer my-1"><span className="text-accent-lighter">Click to upgrade your plan</span></li></ul></div>}>
                                    <div onClick={() => handleChange(color_palette.value == -1 && !customColorSupported? null : (color_palette.value == -1 ? { ...color_palette, 'colors': customColors } : color_palette))} className={`${colorPalette && color_palette.value == colorPalette.value ? 'bg-jacarta-50 dark:bg-jacarta-700' : ''} ${color_palette.value == -1 && !customColorSupported? '' : 'cursor-pointer hover:bg-jacarta-100 dark:hover:bg-jacarta-600'} rounded-md relative ${color_palette.value != -1 ? 'h-11' : 'h-14'}`}>
                                        {color_palette.value == -1 && !customColorSupported && (<div className="flex justify-between items-center absolute w-full h-full dark:bg-jacarta-600 bg-jacarta-300 bg-opacity-50 dark:bg-opacity-50 block rounded-md" style={{ zIndex: 2 }}>

                                        </div>)}
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            sx={{
                                                height: '100%'
                                            }}
                                            value={colorPalette.value}
                                            // disabled={(color_palette.value == -1 && !customColorSupported) ? true : false}
                                            // disabled={true}
                                            disabled={colorPatternDisabled || (color_palette.value == -1 && !customColorSupported) }
                                        >
                                            <div className="grid grid-cols-9 h-full">
                                                <div className="w-full h-full flex justify-center items-center">
                                                    <Radio
                                                        disabled={colorPatternDisabled || (color_palette.value == -1 && !customColorSupported) }
                                                        sx={{
                                                            color: '#8358FF',
                                                            '&.Mui-checked': {
                                                                color: '#8358FF',
                                                            },
                                                        }}
                                                        value={color_palette.value}
                                                    />
                                                </div>
                                                {
                                                    color_palette.value > -1 ? color_palette.colors.map((color, index) => {
                                                        return (
                                                            <div key={index} className="w-full h-full col-span-2">
                                                                <div className="rounded-lg w-full h-full p-1">
                                                                    <div className="rounded-md w-full h-full border dark:border-jacarta-600 border-jacarta-200" style={{ backgroundColor: color }}>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                        :
                                                        <div className="col-span-8 flex flex-col justify-center items-center w-full">
                                                            <p className="text-xs dark:text-jacarta-300 text-jacarta-600">Pick Custom Colors{
                                                                customColorSupported ? (<></>) : (<span className="pro-label">(Pro)</span>)
                                                            }</p>
                                                            <div className="grid grid-cols-4 h-full w-full">
                                                                {
                                                                    customColors.map((color, index) => {
                                                                        return (
                                                                            <div key={index} className="w-full h-full h-10">
                                                                                <div className="rounded-lg w-full h-full p-1">
                                                                                    <div className="rounded-md w-full h-full border dark:border-jacarta-600 border-jacarta-200">
                                                                                        <ColorPicker setCustomerColor={setCustomerColor} colorIndex={index} colorInit={color} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </Tippy>
                            )
                        })
                    }
                </div>
                <div className="my-2 w-full">
                    <div className="flex justify-start">
                        <label
                            htmlFor="design-type"
                            className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600 pr-2">
                            Strength of color pattern
                        </label>
                        <Tippy interactive={true} interactiveBorder={20} delay={100} content={<div className="px-5 py-3"><ul><li>Increasing this will</li><li>increase the importance</li><li>of the color pattern</li><li>in the generated image</li></ul></div>}>
                            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center" style={{ zIndex: 1 }}><b>?</b></span>
                        </Tippy>
                    </div>
                    <div className="px-1 md:px-4">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <CustomSlider
                                    aria-label="Always visible"
                                    value={colorScale}
                                    min={1}
                                    step={1}
                                    max={100}
                                    marks={marks}
                                    disabled={colorPatternDisabled}
                                    valueLabelDisplay="auto"
                                    onChange={(event, newValue) => { onScaleChange(newValue) }}
                                    color="secondary"
                                />
                            </Grid>
                            <Grid item sx={{
                                '&.MuiGrid-root': {
                                    padding: '0px 4px 0px 4px',
                                    marginLeft: '10px'
                                },
                            }}>
                                <div className="relative">
                                    <span className='border-0 border-b p-0 ml-3 pb-2 w-10 px-1 text-number bg-transparent text-jacarta-600 dark:text-jacarta-300'>
                                        {colorScale} %
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}
