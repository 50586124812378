import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { ReactSketchCanvas } from "react-sketch-canvas";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { CustomSlider, marks } from "../slider/customSlider";
import 'react-image-crop/dist/ReactCrop.css'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


const EditInpaint = forwardRef((props, ref) => {
    const { targetCdnImageUrl, inpaintingConfig, setInpaintingConfig, getMask } = props;
    
    const canvasRef = useRef(null);
    const imgRef = useRef(null);
    const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

    const [offset, setOffset] = useState({ left: 0, top: 0 });
    const [position, _setPosition] = useState({ x: 0, y: 0 });
    const positionRef = React.useRef(position);
    const setPosition = data => {
        positionRef.current = data;
        _setPosition(data);
    };
    const [overlayDimensions, setOverlayDimensions] = useState({ width: 512, height: 512 });
    const [dataURI, setDataURI] = useState("");
    const [showStrokeSize, setShowStrokeSize] = useState(false);

    const updateDimensions = () => {
        if (imgRef.current && imgRef.current.offsetWidth > 0 && imgRef.current.offsetHeight > 0) {
            setImgDimensions({
                width: imgRef.current.offsetWidth,
                height: imgRef.current.offsetHeight,
            });
        }
    };

    useEffect(() => {
        if (inpaintingConfig && !inpaintingConfig.active) {
            resetEverything();
        }
    }, [inpaintingConfig])

    useEffect(() => {
        updateDimensions();

        const handleResize = () => {
            updateDimensions();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [imgRef.current]);

    useImperativeHandle(ref, () => ({
        async onImageExport() {
            // console.log('onImageExport called');
            if (!canvasRef || !canvasRef.current) return;

            const maskImage = await canvasRef.current.exportImage("png");
            setDataURI(maskImage);
            const maskImageBlob = await fetch(maskImage).then(r => r.blob());
            const maskImageFile = new File([maskImageBlob], "mask.png", { type: "image/png" });
            return maskImageFile;
        },
        async onPathExport() {
            // console.log('onImageExport called');
            if (!canvasRef || !canvasRef.current) return;

            const path = await canvasRef.current.exportPaths();
            return path;
        },
        async loadPath(pathData) {
            // console.log('loadPath called');
            if (!canvasRef || !canvasRef.current) return false;
            // console.log('loadPath call started');
            const _ = await canvasRef.current.loadPaths(pathData);
            // console.log('loadPath call finished');
            return true;
        }
    }));

    function updateOverlayDimensions() {
        // console.log('updateOverlayDimensions is called 1');
        if (imgRef && imgRef.current) {
            // console.log('updateOverlayDimensions is called 2');
            const imageWidth = imgRef.current.clientWidth;
            const naturalImageWidth = imgRef.current.naturalWidth;
            const overlayWidth = imageWidth; // Math.round(Math.min((512 * imageWidth) / naturalImageWidth, imgRef.current.clientHeight));
            const overlayHeight = imgRef.current.clientHeight; // overlayWidth; // Assuming the overlay is always a square
            // console.log(`imageWidth [${imageWidth}] - naturalImageWidth [${naturalImageWidth}]`);
            // console.log(`overlayWidth [${overlayWidth}] - overlayHeight [${overlayHeight}]`);

            setOverlayDimensions({
                width: overlayWidth,
                height: overlayHeight,
            });

            const offSetData = {
                left: 0, // positionRef.current.x * overlayWidth,
                top: 0, // positionRef.current.y * overlayHeight,
            }

            setOffset(offSetData);

            // console.log('new offset', JSON.stringify(offSetData));
            // console.log('imageWidth inside', JSON.stringify(imageWidth));
            // console.log('positionRef inside', JSON.stringify(positionRef.current));
        }
    };

    const handleMouseMove = (e) => {
        return;
        if (cropped || !inpaintingConfig) {
            // console.log('cropped', cropped);
            return;
        }

        const containerRect = e.currentTarget.getBoundingClientRect();

        const left = e.clientX - containerRect.left - overlayDimensions.width / 2;
        const top = e.clientY - containerRect.top - overlayDimensions.height / 2;

        // Ensure the overlay stays within the boundaries
        const clampedLeft = Math.round(Math.min(Math.max(left, 0), containerRect.width - overlayDimensions.width));
        const clampedTop = Math.round(Math.min(Math.max(top, 0), containerRect.height - overlayDimensions.height));

        setOffset({
            left: clampedLeft,
            top: clampedTop,
        });

        setPosition({
            x: clampedLeft / overlayDimensions.width,
            y: clampedTop / overlayDimensions.height,
        });

        // console.log('position', JSON.stringify({
        //     x: clampedLeft / overlayDimensions.width,
        //     y: clampedTop / overlayDimensions.height,
        // }));
    };

    useEffect(() => {
        updateOverlayDimensions();
        window.addEventListener('resize', updateOverlayDimensions);
        return () => window.removeEventListener('resize', updateOverlayDimensions);
    }, []);

    function scaleInput(x) {
        var m = 15 / 100;
        var b = 5;
        return m * x + b;
      }

    const [eraseMode, setEraseMode] = useState(false)
    useEffect(() => {
        if (canvasRef && canvasRef.current) {
            canvasRef.current.eraseMode(eraseMode);
        }
    }, [eraseMode, setEraseMode]);

    function resetEverything() {
        setPosition({ x: 0, y: 0 });
        setOffset({ left: 0, top: 0 });
        setOverlayDimensions({ width: 512, height: 512 });
        setEraseMode(false);
        setCropped(false);
        if (canvasRef && canvasRef.current) {
            canvasRef.current.resetCanvas();
        } 
        // canvasRef.current.resetCanvas();
    }

    function resetCrop() {
        if (canvasRef && canvasRef.current) {
            canvasRef.current.resetCanvas();
        }        
        setCropped(false);
    }

    useEffect(() => {
        if (inpaintingConfig && !inpaintingConfig.active) {
            resetEverything();
        }
        else {
            updateOverlayDimensions();
        }
    }, [inpaintingConfig])

    const [cropped, setCropped] = useState(false);
    const handleClick = async (e) => {
        // console.log("div clicked 1")
        setShowStrokeSize(false);
        if (cropped || !inpaintingConfig || (inpaintingConfig && !inpaintingConfig.active)) {
            // console.log("div clicked 3")
            // console.log('cropped', cropped);
            return;
        }

        const containerRect = e.currentTarget.getBoundingClientRect();
        const img = new Image();

        img.crossOrigin = 'anonymous'; // Add this line
        img.src = `/api/utils/image-proxy?url=${targetCdnImageUrl}`;
        setInpaintingConfig(prev => ({
            ...prev,
            active: true,
        }));

        img.onload = async () => {
            // console.log('position click', JSON.stringify(position));
            const x = Math.round((offset.left * imgRef.current.naturalWidth) / imgRef.current.clientWidth) // Math.min(Math.max(offset.left, 0), containerRect.width - overlayDimensions.width); // Math.max((position.x * naturalImageWidth) / 100, 0); // Math.min(Math.max(offset.left, 0), containerRect.width - overlayDimensions.width);
            const y = Math.round((offset.top * imgRef.current.naturalHeight) / imgRef.current.clientHeight) // Math.min(Math.max(offset.top, 0), containerRect.height - overlayDimensions.height); // Math.max((position.y * naturalImageHeight) / 100, 0); // Math.min(Math.max(offset.top, 0), containerRect.height - overlayDimensions.height);
            // console.log(`x [${x}] - y [${y}]`);

            const clampedLeft = (x * imgRef.current.clientWidth) / imgRef.current.naturalWidth;
            const clampedTop = (y * imgRef.current.clientHeight) / imgRef.current.naturalHeight;

            setOffset({
                left: 0,
                top: 0,
            });

            setPosition({
                x: 0,
                y: 0,
            });
            // let width = imgRef.current.clientWidth; // Math.min(imgRef.current.naturalWidth, 512);
            // let height = imgRef.current.clientHeight; // Math.min(imgRef.current.naturalHeight, 512); // (imgRef.current.clientHeight / naturalImageHeight) * overlayDimensions.height;

            // width = Math.min(width, height);
            // height = Math.min(width, height);

            // console.log(`overlayDimensions.width: [${overlayDimensions.width}] - imgRef.current.clientWidth [${imgRef.current.clientWidth}] - naturalImageWidth [${naturalImageWidth}]`);
            // console.log(`width: [${width}] - height [${height}]`);

            // const canvas = document.createElement('canvas'); // document.getElementById('canvas');
            // canvas.width = width;
            // canvas.height = height;
            // const ctx = canvas.getContext('2d');

            // ctx.drawImage(img, x, y, width, height, 0, 0, width, height);
            // const imageUrl = canvas.toDataURL("image/png");
            // console.log('x, y, overlayDimensions.width, overlayDimensions.height, 0, 0, overlayDimensions.width, overlayDimensions.height', x, y, overlayDimensions.width, overlayDimensions.height, 0, 0, overlayDimensions.width, overlayDimensions.height);

            // console.log('imageUrl', imageUrl);
            // const croppedImageBlob = await fetch(imageUrl).then(r => r.blob());
            // const croppedImageFile = new File([croppedImageBlob], "cropped.png", { type: "image/png" });

            setCropped(true);

            // setInpaintingConfig(prev => ({
            //     ...prev,
            //     croppedImage: croppedImageFile,
            // }));
        }
    };

    const [canvasProps, setCanvasProps] = useState({
        width: "100%",
        height: "100%",
        // backgroundImage: "",
        preserveBackgroundImageAspectRatio: "xMidYMid slice",
        strokeWidth: 50,
        eraserWidth: 50,
        strokeColor: "#ffffff",
        canvasColor: "transparent",
        // style: { borderRight: "1px solid #CCC" },
        // svgStyle: {},
        className: "cropped-image w-full h-full",
        exportWithBackgroundImage: false,
        // withTimestamp: true,
        allowOnlyPointerType: "all",
        withViewBox: false,
    });

    return (
        <div className="h-full w-full flex flex-col justify-center items-center">
            <div className={`image-container relative inline-block ml-10 mr-4`}>
                <div className={`image-container relative inline-block`} onMouseMove={handleMouseMove} onClick={handleClick}>
                    <img ref={imgRef}
                        src={targetCdnImageUrl}
                        alt={targetCdnImageUrl}
                        className={`w-[80] object-cover block rounded-b-md rounded-r-md`}
                    />
                    {inpaintingConfig && inpaintingConfig.active &&
                        (<div className="absolute bg-transparent border-2 border-accent" style={{
                            left: offset.left,
                            top: offset.top,
                            width: "100%",
                            height: "100%",
                            // transition: 'width 0.3s, height 0.3s, top 0.3s, left 0.3s'
                        }}>
                                <div className="relative w-full h-full">
                                    <ReactSketchCanvas
                                        ref={canvasRef}
                                        {...canvasProps}
                                    />
                                </div>
                        </div>)}
                </div>
                <div className="absolute -left-10 top-0 py-2 w-10 flex rounded-l-md flex-col justify-center items-center text-center z-10 bg-gray-600 hover:bg-opacity-90 dark:hover:bg-opacity-90 bg-opacity-50 dark:bg-opacity-50">
                    <Tippy content={<div className="px-5 py-3"><span>Magic Edit</span></div>}>
                        <div className="flex justify-center cursor-pointer text-center py-2"
                            onClick={() => {
                                setInpaintingConfig(prev => ({
                                    ...prev,
                                    active: !prev.active,
                                }));
                            }}>
                            <i className={`${inpaintingConfig && !inpaintingConfig.active ? 'fal fa-paint-brush-alt' : 'fal fa-times'} text-sm text-white hover:text-accent-light rounded-md hover:bg-opacity-100`}></i>
                        </div>
                    </Tippy>
                    {
                        inpaintingConfig && inpaintingConfig.active && (
                            <>
                            <Tippy content={<div className="px-5 py-3"><span>{eraseMode ? 'Edit Mode' : 'Eraser Mode'}</span></div>}>
                                <div className="flex flex-col justify-center cursor-pointer text-center w-7 h-7"
                                    onClick={() => {
                                        setEraseMode(prev => !prev)
                                    }}>
                                    <i className={`${eraseMode ? 'fal fa-pen' : 'fal fa-eraser'} text-base text-white hover:text-accent-light hover:bg-opacity-100`}></i>
                                </div>
                            </Tippy>
                            <Tippy content={<div className="px-5 py-3"><span>Stroke/Eraser Size</span></div>}>
                                <div className="relative flex flex-col justify-center cursor-pointer text-center w-7 h-7"
                                    onClick={() => {
                                        setShowStrokeSize(prev => !prev)
                                    }}>
                                    <i className={`fas fa-circle text-white hover:text-accent-light hover:bg-opacity-100`} style={{
                                        fontSize: `${scaleInput(canvasProps.strokeWidth)}px`}}>
                                    </i>
                                    {showStrokeSize && (<div className="absolute left-0 -bottom-16 w-52 bg-opacity-70 bg-jacarta-700 pl-4 pr-6 py-2 rounded flex flex-col justify-center">
                                        <CustomSlider
                                            aria-label="Always visible"
                                            value={canvasProps && canvasProps.strokeWidth? canvasProps.strokeWidth : canvasProps.strokeWidth}
                                            min={1}
                                            step={1}
                                            max={100}
                                            marks={marks}
                                            disabled={false}
                                            valueLabelDisplay="auto"
                                            onChange={(event, newValue) => {
                                                setCanvasProps(prev => ({
                                                    ...prev,
                                                    strokeWidth: newValue,
                                                    eraserWidth: newValue,
                                                }));
                                            }}
                                            color="secondary"/>
                                    </div>)}
                                </div>
                            </Tippy>
                        </>
                        )
                    }                    
                </div>
            </div>
        </div>
    )
});

EditInpaint.displayName = 'EditInpaint';

export default EditInpaint
