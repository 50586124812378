import React, { useState, useEffect, useRef, use } from "react";
import {
  design_types_data,
  room_types_data,
  model_types_data,
  building_types_data,
  ext_design_types_data,
  visibility_types_data,
  setValueFromName,
  conditionsList,
  conditionsValues,
} from "../../data/model_configs/interface_data";
import { useForm, Controller } from "react-hook-form";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import {
  CoreDesignPosition,
  URLs,
  CoreDesignUseCase,
  IdeasActions,
  ComponentTypes,
} from "../../utils/enums";
import UploadeWrapper from "../uploader/uploadeWrapper";
import {
  loginModalShow,
  setSubStatus,
  renewSubModalShow,
  plansModalShow,
} from "../../redux/counterSlice";
import { ErrorCodes, SpaceType } from "../../utils/enums";
import { useSelector, useDispatch } from "react-redux";
import { SubscriptionStatus } from "../../utils/enums_backend";
import { designFeatureAccessControl } from "../../utils/managedSub";
import { modeling_name_to_no } from "../../data/model_configs/backend_data.js";
import { CustomSlider } from "../slider/customSlider";
import EditInpaint from "./editInpaint";
import Grid from "@mui/material/Grid";
import CustSwitch from "../customComps/switch";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import toast from "react-hot-toast";
import AdvancedSettings from "./AdvancedSettings/advancedSettings.js";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import DavinteStyles from "./davinteStyles";
import { baseImages } from "../../data/model_configs/interface_data"


export default function DesignCore(props) {
  const dispatch = useDispatch();
  const { subStatus } = useSelector((state) => state.counter);
  const {
    panelPosition,
    addIdea,
    setSelfIsClose,
    useCase,
    actionType,
    actionData,
    recentDesign,
    setLoading,
    loading,
  } = props;
  const positionStyle =
    panelPosition && panelPosition === CoreDesignPosition.SIDE
      ? "grid-cols-1"
      : "grid-cols-1 md:grid-cols-2";
  const { data: session, status } = useSession();
  const router = useRouter();
  const { query } = useRouter();
  const debug = query.debug;

  const form = useRef(null);
  const [internalLoader, setInternalLoader] = useState(false);
  const defaultModelID = "1";

  const [openV, setOpen] = React.useState(false);
  const handleOpenAuth = () => dispatch(loginModalShow(true));
  const [davinteStyle, setDavinteStyle] = useState(true);
  const [advancedSettings, setAdvancedSettings] = useState(true);
  const noSelection = { value: -2, colors: [], color_scale: 75 };
  const [colorPalette, setColorPalette] = React.useState(noSelection);
  const [selectedImage, setSelectedImage] = useState(null);
  const [spaceType, setSpaceType] = useState(SpaceType.EXTERIOR);
  const [inPaintPath, setInPaintPath] = useState(null);
  const [conditionValue, setConditionValue] = useState({
    SEASON: conditionsList["SEASON"][0],
    WEATHER: conditionsList["WEATHER"][0],
    TIME_OF_DAY: conditionsList["TIME_OF_DAY"][0],
    CLIMATE: conditionsList["CLIMATE"][0],
    LANDSCAPE: conditionsList["LANDSCAPE"][0],
    LIGHTING_CONDITIONS: conditionsList["LIGHTING_CONDITIONS"][0],
    TIME_OF_DAY_INT: conditionsList["TIME_OF_DAY_INT"][0],
  });

  useEffect(() => {
    setValue("advanced_styles", JSON.stringify(conditionValue));
  }, [conditionValue]);

  const [files, setFiles] = useState([]);
  const [filesStyler, setFilesStyler] = useState([]);
  const [inpaintingConfig, setInpaintingConfig] = useState({
    active: false,
    strength: 100,
    // styler: null
  });

  useEffect(() => {
    if (inPaintPath) {
      setTimeout(() => {
        // Perform the action after 2 seconds
        const _ = refInpainting.current.loadPath(inPaintPath);
      }, 1000);
    }
  }, [inPaintPath]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
    unregister
  } = useForm({
    defaultValues: {
      image: null,
    },
  });

  const refInpainting = useRef();

  const onSubmit = async (data) => {
    if (loading) return;
    if (!session || status == "unauthenticated") {
      handleOpenAuth();
      return;
    }
    if (!subStatus || subStatus.status != SubscriptionStatus.ACTIVE) {
      dispatch(renewSubModalShow());
      return;
    }

    if (setLoading) setLoading(true);
    setInternalLoader(true);
    let formData = new FormData();

    for (let k in data) {
      if (parseValOut.includes(k)) {
        if (data[k]) {
          formData.append(k, data[k]["value"]);
        } else {
          formData.append(k, 2);
        }
      } else {
        formData.append(k, data[k]);
      }
    }

    formData.append("imageCount", imageCount);

    if (
      refInpainting &&
      refInpainting.current &&
      inpaintingConfig.active
    ) {
      const maskImageFile = await refInpainting.current.onImageExport();
      const maskPath = await refInpainting.current.onPathExport();
      // setInpaintingConfig(prev => ({...prev, drawing: maskPath}))
      formData.append("mask_image", maskImageFile);
      formData.delete("model_type");
      formData.append("model_type", modeling_name_to_no.IN_PAINTING);
      formData.append("mask_path", JSON.stringify(maskPath));
    }
    formData.append("magic_edit_configs", JSON.stringify(inpaintingConfig));

    const pid = router.asPath;
    const res = await fetch(URLs.ADD_DESIGN, {
      method: "POST",
      body: formData,
    })
      .then(async (res) => {
        if (setLoading) setLoading(false);
        setInternalLoader(false);
        const res_json = await res.json();

        if (res_json && res_json.sub) {
          dispatch(setSubStatus(res_json.sub));
        }
        if (res.status == ErrorCodes.SUCCESS_ADD) {
          const { design } = res_json;
          setFiles([]);
          setFilesStyler([]);
          if (pid.includes("/ideas")) {
            if (addIdea && design) addIdea(design);
            if (panelPosition === CoreDesignPosition.CENTERED) {
              if (setSelfIsClose) setSelfIsClose(false);
            }
          } else {
            router.push({
              pathname: "/ideas",
            });
          }
          return res;
        } else if (res.status == ErrorCodes.NO_SUBSCRIPTION) {
          toast.error(
            `You don't have enough credit - please upgrade to a pro plan`,
            {
              duration: 4000,
            }
          );
          dispatch(plansModalShow());
        }
      })
      .catch((error) => {
        if (setLoading) setLoading(false);
        setInternalLoader(false);
        toast.error(
          `Something went wrong - please try again later`,
          {
            duration: 4000,
          }
        );
      });
  };

  const parseValOut = [
    "design_type",
    "room_type",
    "ext_design_type",
    "building_type",
  ];

  const marksNoImages = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
  ];

  useEffect(() => {
    if (actionData) {
      setFormInitVals(actionData, true);
    } else if (recentDesign) {
      setFormInitVals(recentDesign, false);
    } else {
      setValue("action_type", actionType);
      setValue("design_type", design_types_data[0]);
      setValue("room_type", room_types_data[0]);
      setValue("building_type", building_types_data[0]);
      setValue("ext_design_type", ext_design_types_data[0]);
      // setValue("space_type", SpaceType.EXTERIOR);
      // console.log("Cold Start =====================", baseImages[0]);
      setSelectedImage(baseImages[0]);

      setValue("user_prompt", "");
      setImageCount(4);

      if (!session || !subStatus) {
        setValue("model_type", defaultModelID);
        setValue("visibility_type", "0");
        return;
      }

      setValue(
        "model_type",
        (subStatus.plan.supportedModels.length - 1).toString()
      );
      setValue(
        "visibility_type",
        subStatus.plan.isPrivateSupported ? "1" : "0"
      );
    }

    if (getValues("model_type") == modeling_name_to_no.IMG_TO_IMG_SDXL) {
      setValue("model_type", defaultModelID);
    }

    // console.log('user_prompt');
    // setValue('user_prompt', 'asdasdasd');
  }, [actionData, recentDesign]);

  useEffect(() => {
    if (!advancedSettings) {
      setColorPalette(noSelection);
    }
  }, [advancedSettings, setAdvancedSettings]);

  useEffect(() => {
    if (colorPalette && colorPalette.value != -2) {
      setValue("color_palette", JSON.stringify(colorPalette));
    } else {
      setValue("color_palette", null);
    }
  }, [colorPalette, setColorPalette]);

  useEffect(() => {
    setValue("davinte_style", davinteStyle);
  }, [davinteStyle, setDavinteStyle]);

  useEffect(() => {
    if (inpaintingConfig && inpaintingConfig.active) {
      setDavinteStyle(true);
      // setValue("user_prompt", "");
      setColorPatternDisabled(true);
      setModelId(modeling_name_to_no.IN_PAINTING);
      setStyleTab(1);
    } else {
      if (modelId != "0") {
        setColorPatternDisabled(false);
      }
    }
  }, [inpaintingConfig]);

  function scaleToStandardRange(minRange, maxRange, value) {
    if (value < minRange || value > maxRange) {
        throw new Error("Value must be within the provided range");
    }

    return ((value - minRange) / (maxRange - minRange)) * 100;
}

  function setFormInitVals(data, copyData = false) {
    const designVal = setValueFromName(design_types_data, data.styleName);
    if (designVal) setValue("design_type", designVal);

    const roomVal = setValueFromName(room_types_data, data.roomTypeName);
    if (roomVal) setValue("room_type", roomVal);

    const buildingVal = setValueFromName(
      building_types_data,
      data.buildingTypeName
    );
    if (buildingVal) setValue("building_type", buildingVal);

    const extDesignVal = setValueFromName(
      ext_design_types_data,
      data.extStyleName
    );
    if (extDesignVal) setValue("ext_design_type", extDesignVal);

    // setValue("space_type", data.spaceType);
    setSpaceType(data.spaceType);
    // console.log("data.spaceType in init func 2: ", data.spaceType);

    if (data.interfaceConfig) {
      // setValue("advanced_styles", data.interfaceConfig);
      setConditionValue(JSON.parse(data.interfaceConfig));
    }

    const modelVal = setValueFromName(model_types_data, data.modelName);
    if (
      data.modelName &&
      modelVal &&
      modelVal.value != parseInt(modeling_name_to_no.IN_PAINTING) &&
      modelVal.value != parseInt(modeling_name_to_no.UPSCALE)
    ) {
      setValue("model_type", modelVal.value.toString());
      if (modelVal.value == 0) {
        setColorPatternDisabled(true);
      } else {
        setColorPatternDisabled(false);
      }
    } else {
      setValue("model_type", defaultModelID);
      setColorPatternDisabled(true);
    }

    // console.log("modelVal: ", modelVal);
    if (copyData && modelVal && modelVal.value == parseInt(modeling_name_to_no.IN_PAINTING) && data.magicEditConfig) {
      // console.log("data.magicEditConfig: ", data.magicEditConfig);
      const magicEditConfig = JSON.parse(data.magicEditConfig);
      // console.log("raw magicEditConfig: ", magicEditConfig);
      if (magicEditConfig.MAGIC_EDIT && magicEditConfig.MAGIC_EDIT.path) {
        setInPaintPath(magicEditConfig.MAGIC_EDIT.path);
      }

      // refInpainting.current.loadPath(JSON.stringify(data.magicEditConfig))
      setInpaintingConfig(prev => ({
          ...prev,
          active: true,
      }));
    }

    const visibilityVal = setValueFromName(
      visibility_types_data,
      data.visibilityName
    );
    if (data.visibilityName && visibilityVal)
      setValue("visibility_type", visibilityVal.value.toString());

    if (data.num_images_per_prompt) setImageCount(data.num_images_per_prompt);

    if (data.id && copyData) setValue("design_id", data.id);
    // console.log('setting action type', actionType);

    if (modelVal.value == parseInt(modeling_name_to_no.IN_PAINTING) || modelVal.value == parseInt(modeling_name_to_no.UPSCALE) || modelVal.value == parseInt(modeling_name_to_no.IMG_TO_IMG_SDXL)) { 
      setDavinteStyle(true);
    }
    else {
      setDavinteStyle(data.isDavintePrompt);
    }

    if (!data.isDavintePrompt && copyData) {
      setValue("user_prompt", data.userPrompt);
    } else {
      // console.log("user_prompt to junk");
      setValue("user_prompt", "");
    }

    if (actionType) {
      setValue("action_type", actionType);
      if (actionType == IdeasActions.EDIT) {
        setValue("source_image_cdn_url", data.targetCdnImageUrl);
        setValue("target_image_path", data.targetImagePath);
        setValue("idea_id", data.ideaId);
      }
    }

    if (modelVal.value == parseInt(modeling_name_to_no.IN_PAINTING) && data.otherConfig) {
      const otherConfigs = data.otherConfig
        ? JSON.parse(data.otherConfig)
        : null;

      // console.log("otherConfig in styler: ", otherConfigs);
      setInpaintingConfig(prev => ({
          ...prev,
          strength: (otherConfigs && otherConfigs["CNET_CONFIGS"] && otherConfigs["CNET_CONFIGS"]['controlnet_conditioning_scale'] && otherConfigs["CNET_CONFIGS"]['controlnet_conditioning_scale']['CANNY'])? otherConfigs["CNET_CONFIGS"]['controlnet_conditioning_scale']['CANNY'] * 100 : 100,
      }));
    }
    else if (data && data.otherConfig) {
      const otherConfigs = data.otherConfig
        ? JSON.parse(data.otherConfig)
        : null;

      const strength = otherConfigs && otherConfigs["CNET_CONFIGS"] && otherConfigs["CNET_CONFIGS"]['controlnet_conditioning_scale'] && otherConfigs["CNET_CONFIGS"]['controlnet_conditioning_scale']['CANNY']? otherConfigs["CNET_CONFIGS"]['controlnet_conditioning_scale']['CANNY'] : 1;
      const scaledStrength = scaleToStandardRange(0, modelVal.value == parseInt(modeling_name_to_no.CNET_SDXL_CANNY)? 40: 100, strength * 100);
      setInpaintingConfig(prev => ({
          ...prev,
          strength: scaledStrength,
      }));
    }

    // console.log('data', data);
    // console.log('type of data.otherConfig', typeof data.otherConfig);
    if (data && data.otherConfig) {
      const otherConfigs = data.otherConfig
        ? JSON.parse(data.otherConfig)
        : null;
      if (
        otherConfigs &&
        "SHUFFLE_CONFIGS" in otherConfigs &&
        (otherConfigs["SHUFFLE_CONFIGS"]["colors"] ||
          otherConfigs["SHUFFLE_CONFIGS"]["colorsOrgSet"])
      ) {
        const colorData = {
          value:
            "colorIndex" in otherConfigs["SHUFFLE_CONFIGS"]
              ? otherConfigs["SHUFFLE_CONFIGS"]["colorIndex"]
              : -2,
          colors: otherConfigs["SHUFFLE_CONFIGS"]["colorsOrgSet"]
            ? otherConfigs["SHUFFLE_CONFIGS"]["colorsOrgSet"]
            : [...new Set(otherConfigs["SHUFFLE_CONFIGS"]["colors"])],
          color_scale:
            "controlnet_conditioning_scale" in otherConfigs["SHUFFLE_CONFIGS"]
              ? parseInt(
                  otherConfigs["SHUFFLE_CONFIGS"][
                    "controlnet_conditioning_scale"
                  ] * 100
                )
              : 100,
        };
        setColorPalette(colorData);
      }
    }
  }

  function modelDifference(list1, list2) {
    const diff1 = list1.filter((el) => !list2.includes(el.label));
    const diff2 = list2.filter(
      (str) => !list1.some((obj) => obj.label === str)
    );

    return diff1.concat(diff2);
  }

  let message = <></>;
  if (subStatus && subStatus.plan) {
    const modelDiff = modelDifference(
      model_types_data,
      subStatus.plan.supportedModels
    );
    message = (
      <div className="px-5 py-3">
        <ul>
          <li>
            The disabled model{modelDiff && modelDiff.length > 1 ? "(s)" : ""}
          </li>
          <li>require an upgrade on your existing plan.</li>
          <li
            onClick={() => dispatch(plansModalShow())}
            className="cursor-pointer my-1"
          >
            <span className="text-accent-lighter">
              Click to upgrade your plan
            </span>
          </li>
          {/* <li><Link className="text-accent-lighter" href="/prop-plan">Learn more</Link></li> */}
        </ul>
      </div>
    );
  }
  // useEffect(() => {

  // }, [subStatus])
  const [imageCount, setImageCount] = useState(1);
  function handleDesignCountChange(event, newValue) {
    var imageCountInt = parseInt(newValue);
    imageCountInt = imageCountInt > 4 ? 4 : imageCountInt ? imageCountInt : 1;
    setImageCount(imageCountInt);
  }

  let advancedSettingsMessage = <></>;
  advancedSettingsMessage = (
    <div className="px-5 py-3">
      <ul>
        <li>
          Toggle to enable <b>advanced settings</b>
        </li>
      </ul>
    </div>
  );

  const [modelId, setModelId] = useState(defaultModelID);
  const [colorPatternDisabled, setColorPatternDisabled] = useState(true);
  function handleModelChanges(e) {
    // console.log('modelId', e);
    if (e && e.target && e.target.value) {
      const modelId = e.target.value;
      if (
        modelId == modeling_name_to_no.IN_PAINTING ||
        modelId == "0" ||
        (inpaintingConfig && inpaintingConfig.active)
      ) {
        setModelId(modelId);
        setColorPatternDisabled(true);
      } else {
        setColorPatternDisabled(false);
      }
    }
  }

  function setStyleTab(index) {
    setDavinteStyle(index == 0 ? true : false);
  }

  useEffect(() => {
    if (selectedImage) {
      setValue("space_type", selectedImage.type);
      setSpaceType(selectedImage.type);
      setValue("template_image", selectedImage.large_url);

      if (selectedImage.type == SpaceType.EXTERIOR) {
        setValue("building_type", selectedImage.space_type);
        setValue("ext_design_type", selectedImage.design_type);
      } else {
        setValue("design_type", selectedImage.design_type);
        setValue("room_type", selectedImage.space_type);
      }
    }
    else {
      // console.log("The selected image is null");
    }
  }, [selectedImage]);

  useEffect(() => {
    if (spaceType) {
      setValue("space_type", spaceType);
    }
  }, [spaceType]);

  return (
    <>
      <form
        ref={form}
        onSubmit={handleSubmit(onSubmit)}
        className={`h-full ${
          panelPosition &&
          panelPosition === CoreDesignPosition.CENTERED &&
          "flex flex-col justify-center w-full"
        }`}
      >
        <div className="w-full flex flex-col justify-center relative">
            {useCase === CoreDesignUseCase.UPLOAD_NEEDED && (
            <div className="mb-6">
                <label className="font-display text-jacarta-700 mb-2 block dark:text-white">
                Pick an image of a space
                <span className="text-red">*</span>
                </label>
                <UploadeWrapper
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                panelPosition={panelPosition}
                files={files}
                setFiles={setFiles}
                errors={errors}
                clearErrors={clearErrors}
                name="image"
                control={control}
                required={selectedImage ? false : true}
                register={register}
                setValue={setValue}
                />
            </div>
            )}
            {useCase === CoreDesignUseCase.EXISTING_IMAGE && (
            <div className="mb-6">
                <EditInpaint
                ref={refInpainting}
                targetCdnImageUrl={actionData.targetCdnImageUrl}
                setInpaintingConfig={setInpaintingConfig}
                inpaintingConfig={inpaintingConfig}
                />
            </div>
            )}
            <DavinteStyles
              control={control}
              spaceType={spaceType}
              setSpaceType={setSpaceType}
              getValues={getValues}
              required={true}
              register={register}
              setValue={setValue}
              errors={errors}
              davinteStyle={davinteStyle}
              setDavinteStyle={setDavinteStyle}
              inpaintingConfig={inpaintingConfig}
              setInpaintingConfig={setInpaintingConfig}
              setStyleTab={setStyleTab}
              panelPosition={panelPosition}
              conditionValue={conditionValue}
              setConditionValue={setConditionValue}
              files={filesStyler}
              setFiles={setFilesStyler}
              clearErrors={clearErrors}
              unregister={unregister}
              design={actionData}
              styler_file_form_name={"styler_image"}
            />
            <div className="mb-6 w-full flex-col justify-center">
                <div className={`grid ${positionStyle}`}>
                    <div className="my-2 flex flex-col justify-center">
                    <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex justify-start">
                        Model Type
                        {(!designFeatureAccessControl(
                        session,
                        subStatus,
                        ComponentTypes.AI_APPRENTICE
                        ) ||
                        !designFeatureAccessControl(
                            session,
                            subStatus,
                            ComponentTypes.AI_NAVIGATOR
                        ) ||
                        !designFeatureAccessControl(
                            session,
                            subStatus,
                            ComponentTypes.AI_SAGA
                        )) && (
                        <Tippy
                            interactive={true}
                            interactiveBorder={20}
                            delay={100}
                            content={message}
                        >
                            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
                            <b>?</b>
                            </span>
                        </Tippy>
                        )}
                    </span>
                    {!inpaintingConfig || !inpaintingConfig.active ? (
                        <Controller
                        control={control}
                        rules={{ required: true }}
                        name="model_type"
                        render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                        }) => (
                            <>
                            <ToggleButtonGroup
                                color="primary"
                                value={value ? value : "0"}
                                exclusive
                                onChange={(val) => {
                                onChange(val);
                                handleModelChanges(val);
                                // console.log("val: ", val);
                                }}
                                aria-label="Platform"
                            >
                                {model_types_data
                                .filter((item) => (item.display) || (debug && item.displayDebug))
                                .map((model) => {
                                    return (
                                    <ToggleButton
                                        key={model.value}
                                        value={model.value.toString()}
                                    >
                                        {model.shortLabel}
                                        {!designFeatureAccessControl(
                                        session,
                                        subStatus,
                                        model.modelType
                                        ) && <span className="pro-label">(Pro)</span>}
                                    </ToggleButton>
                                    );
                                })}
                            </ToggleButtonGroup>
                            </>
                        )}
                        />
                    ) : (
                        <>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            aria-label="Platform"
                            value="3"
                        >
                            <ToggleButton value="3" selected={true}>Magic Edit</ToggleButton>
                        </ToggleButtonGroup>
                        </>
                    )}
                    </div>
                    <div className="relative">
                    <div className="my-2 flex flex-col justify-center">
                        <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex justify-start">
                        Visibility
                        {!designFeatureAccessControl(
                            session,
                            subStatus,
                            ComponentTypes.PRIVATE_VISIBILITY
                        ) && (
                            <Tippy
                            interactive={true}
                            interactiveBorder={20}
                            delay={100}
                            content={
                                <div className="px-5 py-3">
                                <ul>
                                    <li>To unlock private mode</li>
                                    <li>you can upgrade to the</li>
                                    <li>
                                    <span className="font-display">
                                        Private Pro plan
                                    </span>
                                    </li>
                                    <li
                                    onClick={() => dispatch(plansModalShow())}
                                    className="cursor-pointer my-1"
                                    >
                                    <span className="text-accent-lighter">
                                        Click to upgrade your plan
                                    </span>
                                    </li>
                                </ul>
                                </div>
                            }
                            >
                            {/* <li><Link className="text-accent-lighter" href="/private-mode-plan">Learn more</Link></li> */}
                            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
                                <b>?</b>
                            </span>
                            </Tippy>
                        )}
                        </span>
                        <Controller
                        control={control}
                        rules={{ required: true }}
                        name="visibility_type"
                        render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                        }) => {
                            return (
                            <ToggleButtonGroup
                                color="primary"
                                value={value ? value : "0"}
                                exclusive
                                onChange={(val) => onChange(val)}
                                aria-label="Platform"
                            >
                                <ToggleButton
                                value="1"
                                disabled={
                                    !designFeatureAccessControl(
                                    session,
                                    subStatus,
                                    ComponentTypes.PRIVATE_VISIBILITY
                                    )
                                }
                                >
                                Private
                                {!designFeatureAccessControl(
                                    session,
                                    subStatus,
                                    ComponentTypes.PRIVATE_VISIBILITY
                                ) && <span className="pro-label">(Pro)</span>}
                                </ToggleButton>
                                <ToggleButton value="0">Public</ToggleButton>
                            </ToggleButtonGroup>
                            );
                        }}
                        />
                    </div>
                    </div>
                    <AdvancedSettings
                      colorPalette={colorPalette}
                      setColorPalette={setColorPalette}
                      panelPosition={panelPosition}
                      colorPatternDisabled={colorPatternDisabled}
                      modelId={modelId}
                      conditionValue={conditionValue}
                      setConditionValue={setConditionValue}
                    />
                    <div className="relative">
                    <div className="my-2 flex flex-col justify-center">
                        <span className="ml-2 mb-2 text-sm dark:text-jacarta-300 text-jacarta-600 flex justify-start">
                        No. of Designs to Generate
                        <Tippy
                            interactive={true}
                            interactiveBorder={20}
                            delay={100}
                            content={
                            <div className="px-5 py-3">
                                <ul>
                                <li>This counts towards</li>
                                <li>your budget</li>
                                </ul>
                            </div>
                            }
                        >
                            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
                            <b>?</b>
                            </span>
                        </Tippy>
                        </span>
                        <div className="px-1 md:px-4">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                            <CustomSlider
                                aria-label="Always visible"
                                value={imageCount}
                                min={1}
                                step={1}
                                max={4}
                                marks={marksNoImages}
                                valueLabelDisplay="auto"
                                onChange={handleDesignCountChange}
                                color="secondary"
                            />
                            </Grid>
                            <Grid
                            item
                            sx={{
                                "&.MuiGrid-root": {
                                padding: "0px 4px 0px 4px",
                                marginLeft: "10px",
                                },
                            }}
                            >
                            <div className="relative">
                                <span className="border-0 border-b p-0 ml-3 pb-2 w-10 px-3 text-number bg-transparent text-jacarta-600 dark:text-jacarta-300">
                                {imageCount}
                                </span>
                            </div>
                            </Grid>
                        </Grid>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center mb-3 sticky bottom-5 md:bottom-2 w-full">
                <button
                    type="submit"
                    className="button-main"
                    disabled={loading || internalLoader}
                >
                    {loading || internalLoader ? "Generating ..." : "Generate"}
                </button>
            </div>
        </div>
      </form>
    </>
  );
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
