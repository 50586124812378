import { CustomSlider, marks } from "../../slider/customSlider";
import Styler from "./../AdvancedSettings/styler";
import Accordion from "../../modal/accordion";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional


export default function AdvancedConditions(props) {
  const {
    control,
    inpaintingConfig,
    setInpaintingConfig,
    panelPosition,
    files,
    setFiles,
    errors,
    clearErrors,
    form_name,
    setValue,
    windowOpen,
    creativityTitle,
    isInPainting,
    unregister,
    design
  } = props;  

  function onImageUploadUnselect() {}

  function onImageUploadSelect() {}

  function getToolTip() {
    return (<Tippy
        interactive={true}
        interactiveBorder={20}
        delay={100}
        content={
            <div className="px-5 py-3 w-full flex flex-col justify-center">
                <span className="normal-case">
                    This section is to provide advanced style settings for the final generation. If you are not sure what to do, you can leave the default settings.
                </span>
            </div>
        }
        >
        <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
            <b>?</b>
        </span>
    </Tippy>)
  }

  return (
    <Accordion title={"Advanced Style Settings"} defaultOpen={windowOpen} dynamicEvent={"styler"} tooltip={getToolTip()}>
      <div className="w-full relative flex flex-col justify-center dark:border-1 border-1 dark:border-jacarta-700 border-jacarta-100 px-3 py-3 w-full">
        <div className="w-full flex flex-col justify-center">
          <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex w-fit justify-start">
            {creativityTitle} 
            {/* <Tippy
            interactive={true}
            interactiveBorder={20}
            delay={100}
            content={
              <div className="px-5 py-3 w-full flex flex-col justify-center">
                <span className="normal-case">
                    This value determines the level of <b>{creativityTitle}</b> in the final generation: {isInPainting? "a setting of 100 represents the highest level of creativity, while a setting of 0 makes no changes to the source image." : "a value closer to 100 preserves the core foundation and geometry of the source image while a value closer to zero ignores the geometry of the source image." }
                </span>
                <span className="normal-case">
                    For example, setting <b>{creativityTitle}</b> to 100 means that the underlying structure of the source image provided above is ignored in order to generate the final image. Instead, the final image is created based on your prompt provided above {isInPainting? "" : ", the conditions selected in the Davinte Style"}  or the style image provided below.
                </span>
              </div>
            }
          >
            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
              <b>?</b>
            </span>
          </Tippy> */}
          </span>
          <div className="px-1 md:px-4 w-full flex flex-col justify-center rounded-lg border border-dashed dark:border-jacarta-600 border-jacarta-100">
            <div className="w-full h-fit p-2">
                <CustomSlider
                    aria-label="Always visible"
                    value={inpaintingConfig && inpaintingConfig.strength? inpaintingConfig.strength : 100}
                    min={0}
                    step={1}
                    max={100}
                    marks={marks}
                    disabled={false}
                    valueLabelDisplay="auto"
                    onChange={(event, newValue) => {
                        setInpaintingConfig((prev) => ({
                            ...prev,
                            strength: newValue,
                        }));
                    }}
                    color="secondary" />
                <span className="text-xs dark:text-jacarta-300 text-jacarta-600">
                    We recommend a value <b>higher than 50</b> to {isInPainting? "increase the creativity in the final design" : "preserve the structure of the source image"}
                </span>
            </div>
          </div>
        </div>
        <div className="w-full pt-3">
          <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600">
            Style Image
          </span>
          <div className="w-full h-fit flex justify-center">
            <Styler
              control={control}
              files={files}
              setFiles={setFiles}
              errors={errors}
              clearErrors={clearErrors}
              form_name={form_name}
              panelPosition={panelPosition}
              unregister={unregister}
              setValue={setValue}
              design={design}
              onImageUploadSelect={onImageUploadSelect}
              onImageUploadUnselect={onImageUploadUnselect}
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
}
