import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { CoreDesignPosition } from "../../../utils/enums";
import {
  building_types_data,
  ext_design_types_data,
} from "../../../data/model_configs/interface_data";
import Select from "react-select";

export default function Exterior(props) {
  const { control, panelPosition } = props;

  return (
    <>
      <div
        className={`py-1 ${
          panelPosition && panelPosition === CoreDesignPosition.SIDE
            ? ""
            : "md:mr-2"
        } relative`}
      >
        <div className="my-1 flex flex-col justify-center">
          <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600">
            Design Type
          </span>
          <Controller
            control={control}
            name="ext_design_type"
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value, ref },
              fieldState: { error },
            }) => {
              return (
                <>
                  <Select
                    inputRef={ref}
                    instanceId="ext_design_type"
                    className="my-react-select-container"
                    classNamePrefix="my-react-select"
                    isClearable={true}
                    isSearchable={true}
                    value={value}
                    placeholder="Design Type"
                    options={ext_design_types_data}
                    onChange={(val) => onChange(val)}
                  />
                  {error && (
                    <span className="text-red">This field is required</span>
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="py-1 relative">
        <div className="my-1 flex flex-col justify-center">
          <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600">
            Building Type
          </span>
          <Controller
            control={control}
            rules={{ required: true }}
            name="building_type"
            render={({
              field: { onChange, onBlur, value, ref },
              fieldState: { error },
            }) => (
              <>
                <Select
                  inputRef={ref}
                  instanceId="building_type"
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  isClearable={true}
                  isSearchable={true}
                  value={value}
                  placeholder="Building Type"
                  options={building_types_data}
                  onChange={(val) => onChange(val)}
                />
                {error && (
                  <span className="text-red">This field is required</span>
                )}
              </>
            )}
          />
        </div>
      </div>
    </>
  );
}
