import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Tabs from "../tabs";
import { CoreDesignPosition, SpaceType } from "../../utils/enums";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Interior from "./davinteStyles/interior";
import Exterior from "./davinteStyles/exterior";
import ConditionsParent from "./AdvancedSettings/conditionsParent";
import Styler from "./AdvancedSettings/styler";
import { CustomSlider, marks } from "../slider/customSlider";
import CustomPromptPanel from "./customPromptPanel";
import AdvancedConditions from "./AdvancedSettings/advancedConditions";


export default function DavinteStyles(props) {
  const {
    control,
    davinteStyle,
    inpaintingConfig,
    setInpaintingConfig,
    setStyleTab,
    panelPosition,
    spaceType,
    setSpaceType,
    conditionValue,
    setConditionValue,
    files,
    setFiles,
    errors,
    clearErrors,
    styler_file_form_name,
    setValue,
    unregister,
    design
  } = props;

  let styleTooltipMessage = <></>;
  if (inpaintingConfig && inpaintingConfig.active) {
    styleTooltipMessage = (
      <div className="px-5 py-3">
        <ul>
          <li>
            Disabled in <b>Magic Edit</b> mode
          </li>
          <li>
            only <b>User Prompt</b> is supported.
          </li>
        </ul>
      </div>
    );
  } else {
    styleTooltipMessage = (
      <div className="px-5 py-3">
        <ul>
          <li>Toggle to switch between</li>
          <li>
            curated <b>Davinte</b> prompts or
          </li>
          <li>
            <b>User Prompts</b>
          </li>
        </ul>
      </div>
    );
  }

  const positionStyle =
    panelPosition && panelPosition === CoreDesignPosition.SIDE
      ? "grid-cols-1"
      : "grid-cols-1 md:grid-cols-2";

  const largeOnSideStyle =
    panelPosition && panelPosition === CoreDesignPosition.SIDE
      ? ""
      : "md:col-span-2";

  function handleSpaceTypeChange(e) {
    if (e && e.target && e.target.value) {
      const spaceTypeTemp = e.target.value;
      setSpaceType(spaceTypeTemp);
    }
  }

  useEffect(() => {
    if (design && design.otherConfig) {
        const otherConfig = JSON.parse(design.otherConfig);
        // console.log("otherConfig in styler: ", otherConfig);
        if (otherConfig && otherConfig["STYLER"] && otherConfig["STYLER"]["REF_IMAGE"]) {
          setShowAdvancedConditions(true)
        }
    }
  }, [design]);
  const [showAdvancedConditions, setShowAdvancedConditions] = useState(inpaintingConfig && inpaintingConfig.active);

  return (
    <div className="w-full">
      <div className="w-full">
        <Tabs
          tabs={[
            {
              name: "Davinte Styles",
            },
            {
              name: "Your Prompt",
            },
          ]}
          index={davinteStyle ? 0 : 1}
          setIndex={setStyleTab}
          tippyMessage={styleTooltipMessage}
        ></Tabs>
      </div>
      <div className="px-1 border-0 dark:border-accent-light border-accent-light items-center border-dashed relative">
        <div className={`grid ${positionStyle}`}>
          {(!inpaintingConfig || !inpaintingConfig.active) &&
            davinteStyle && (
              <>
                <div className={`relative pt-5 ${largeOnSideStyle}`}>
                  <div className="my-2 flex flex-col justify-center">
                    <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex justify-start">
                      Space Type
                      <Tippy
                        interactive={true}
                        interactiveBorder={20}
                        delay={100}
                        content={
                          <div className="px-5 py-3">
                            <ul>
                              <li>Choose whther you want to render</li>
                              <li>
                                an <b>interior</b> or <b>exterior</b>
                              </li>
                            </ul>
                          </div>
                        }
                      >
                        <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
                          <b>?</b>
                        </span>
                      </Tippy>
                    </span>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="space_type"
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => {
                        return (
                          <ToggleButtonGroup
                            color="primary"
                            value={value ? value : SpaceType.EXTERIOR}
                            exclusive
                            onChange={(val) => {
                              onChange(val);
                              handleSpaceTypeChange(val);
                            }}
                            aria-label="Platform"
                          >
                            <ToggleButton value={SpaceType.EXTERIOR}>
                              Exterior
                            </ToggleButton>
                            <ToggleButton value={SpaceType.INTERIOR}>
                              Interior
                            </ToggleButton>
                          </ToggleButtonGroup>
                        );
                      }}
                    />
                  </div>
                </div>
                {spaceType == SpaceType.EXTERIOR ? (
                  <>
                    <Exterior control={control} panelPosition={panelPosition} />
                    
                  </>
                ) : (
                  <>
                    <Interior control={control} panelPosition={panelPosition} />
                    {/* <div className={`py-2 pr-2 ${largeOnSideStyle}`}>
                      <ConditionsParent spaceType={SpaceType.INTERIOR} panelPosition={panelPosition} conditionValue={conditionValue} setConditionValue={setConditionValue} />
                    </div> */}
                  </>
                )}
                <div className={`py-2 ${largeOnSideStyle}`}>
                    <ConditionsParent spaceType={spaceType} panelPosition={panelPosition} conditionValue={conditionValue} setConditionValue={setConditionValue} />
                  </div>
              </>
            )}
          {((inpaintingConfig && inpaintingConfig.active) ||
            !davinteStyle) && (
            <CustomPromptPanel 
              control={control}
              davinteStyle={davinteStyle}
              inpaintingConfig={inpaintingConfig}
              panelPosition={panelPosition} />
          )}
          <div className={`w-full pt-3 ${largeOnSideStyle}`}>
            <AdvancedConditions control={control}
                inpaintingConfig={inpaintingConfig}
                setInpaintingConfig={setInpaintingConfig}
                panelPosition={panelPosition}
                files={files}
                setFiles={setFiles}
                errors={errors}
                clearErrors={clearErrors}
                form_name={styler_file_form_name}
                setValue={setValue}
                design={design}
                unregister={unregister}
                isInPainting={inpaintingConfig && inpaintingConfig.active}
                creativityTitle={inpaintingConfig && inpaintingConfig.active? "Creativity" : "Preserve Geometry" }
                windowOpen={showAdvancedConditions} />
          </div> 
        </div>
      </div>
    </div>
  );
}
