import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";
import { useSession } from "next-auth/react";
import { CoreDesignPosition, SpaceType } from "../../utils/enums";
import { baseImages } from "../../data/model_configs/interface_data";
import FileUpload from "./fileUpload";

export default function UploadeWrapper(props) {
  const {
    control,
    name,
    required,
    setValue,
    errors,
    clearErrors,
    files,
    setFiles,
    panelPosition,
    setSelectedImage,
    selectedImage,
  } = props;
  const { data: session, status } = useSession();

  function changeSelectedImage(image) {
    setSelectedImage(image);
    setFiles([]);
  }

  function onImageUploadUnselect() {
    setSelectedImage(baseImages[0]);
  }

  function onImageUploadSelect() {
    setSelectedImage(null);
  }

  return (
    <div className="rounded-lg border-2 border-dashed bg-white dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex flex-col justify-center">
      {/* md:grid grid-cols-2 gap-2 */}
      <div className="w-full flex justify-center flex-col">
        <div className="w-full pt-5 px-4">
          <p className="dark:text-jacarta-300 text-2xs pb-3">
            Choose one of the following{" "}
            <span className="font-bold">templates</span>
          </p>
        </div>
        <div className="w-full grid grid-cols-2 gap-1 h-auto px-4">
          {baseImages.map((image, index) => {
            return (
              <div
                key={index}
                className={`w-full h-fit flex flex-row relative cursor-pointer ${
                  panelPosition === CoreDesignPosition.SIDE ? "" : "h-20 md:h-32"
                }`}
                onClick={() => changeSelectedImage(image)}
              >
                <div
                  className={`h-full w-full ${
                    selectedImage && image.id == selectedImage.id
                      ? ""
                      : "after:bg-jacarta-900 after:absolute after:rounded after:opacity-50 hover:after:opacity-0 after:inset-0"
                  }`}
                >
                  <img
                    src={`${image.url}`}
                    className={`w-full object-cover ${
                      panelPosition === CoreDesignPosition.SIDE
                        ? "h-20"
                        : "h-20 md:h-full"
                    } rounded cursor-pointer`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full flex flex-center flex-col">
        <div className="w-full pt-6 px-4">
          <p className="dark:text-jacarta-300 text-2xs">
            OR <span className="font-bold">Upload</span> an image of your space
          </p>
        </div>
          <FileUpload 
                files={files}
                setFiles={setFiles}
                errors={errors}
                clearErrors={clearErrors}
                form_name={name}
                control={control}
                required={selectedImage ? false : true}
                setValue={setValue} 
                onImageUploadSelect={onImageUploadSelect}
                errorMessage={"You need to either select an image template or upload an image"}
                heightClass={panelPosition === CoreDesignPosition.SIDE? "h-64 md:h-48": "h-48 md:h-48"}
                onImageUploadUnselect={onImageUploadUnselect}/>
      </div>
    </div>
  );
}
