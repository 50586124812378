import React, { useState, useEffect, useRef, use } from "react";
import { CoreDesignPosition } from "../../../utils/enums";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import ColorPalettes from "../../color_palette/colorPalettes";
import {
  conditionsList,
  conditionsValues,
} from "../../../data/model_configs/interface_data";

export default function conditionNode(props) {
  const { conditionType, conditionValue, setConditionValue } = props;

  function handleConditionClick(condition) {
    setConditionValue({ ...conditionValue, [conditionType]: condition });
  }

  return (
    <div className="w-full">
      <span className="pl-2 pb-1 text-sm dark:text-jacarta-300 text-jacarta-600 flex justify-start capitalize">
        {conditionType.split("_").join(" ").toLowerCase()}
      </span>
      <div className="w-full grid grid-cols-4">
        {conditionsList[conditionType].map((condition) => {
          return (
            <div
              key={condition}
              className={`condition-botton ${
                conditionValue[conditionType] == condition ? "selected" : ""
              }`}
              onClick={() => handleConditionClick(condition)}
            >
              <div className="w-full h-full flex justify-center flex-col items-center">
                <span
                  className={`text-lg ${
                    conditionsValues[conditionType][condition] &&
                    conditionsValues[conditionType][condition].iconClass
                  }`}
                ></span>
              </div>
              <div className="w-full flex justify-center">
                <span className="condition-botton-text">
                  {conditionsValues[conditionType][condition].name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
