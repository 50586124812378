import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Tabs from "../tabs";
import { CoreDesignPosition, SpaceType } from "../../utils/enums";

export default function CustomPromptPanel(props) {
  const {
    control,
    davinteStyle,
    inpaintingConfig,
    panelPosition,
  } = props;

  return (
    <div
      className={`py-1 w-full flex flex-col justify-center ${
        panelPosition && panelPosition === CoreDesignPosition.SIDE
          ? ""
          : "md:col-span-2"
      }`}
    >
      <div className="w-full flex flex-col justify-center">
        <span className="ml-2 mb-1 text-sm dark:text-jacarta-300 text-jacarta-600">
          Your Prompt
        </span>
        <Controller
          control={control}
          rules={{
            required:
              !davinteStyle && !(inpaintingConfig && inpaintingConfig.active),
          }}
          name="user_prompt"
          render={({
            field: { onChange, onBlur, value, ref },
            fieldState: { error },
          }) => (
            <>
              <textarea
                value={value ? value : ""}
                onChange={(val) => onChange(val)}
                rows="5"
                cols="50"
                className="h-16 text-area w-full"
                placeholder={
                  !inpaintingConfig
                    ? "E.g. a cinematic living room with white++ walls"
                    : "E.g. a white beige++ sofa ..."
                }
              />
              {error && <span className="text-red">Prompt is required</span>}
            </>
          )}
        />
      </div>     
    </div>
  );
}
