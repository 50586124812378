import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";
import { useSession } from "next-auth/react";
import { CoreDesignPosition, SpaceType } from "../../utils/enums";
import { baseImages } from "../../data/model_configs/interface_data";

export default function FileUpload(props) {
  const {
    control,
    form_name,
    required,
    setValue,
    clearErrors,
    files,
    errorMessage,
    setFiles,
    onImageUploadUnselect,
    onImageUploadSelect,
    heightClass
  } = props;

  const { data: session, status } = useSession();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      onImageUploadSelect && onImageUploadSelect()

      if (!session || status == "unauthenticated") {
        handleOpenAuth();
      }
    },
    noClick: false,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
  });

  useEffect(() => {
    const setValueAsync = async () => {
      if (files.length == 0) await setValue(form_name, null);
      else {
        await setValue(form_name, files[0]);
        clearErrors(form_name);
      }
    };
    setValueAsync();
  }, [files, setFiles]);

  useEffect(() => {
    // setSelectedImage(baseImages[0])
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const thumbs = files.map((file) => (
    <div
      key={file.name}
      className="h-full w-full relative flex flex-col justify-center items-center p-4"
    >
      <div className="absolute right-6 top-6 p-1 h-7 w-7 flex flex-row-reverse justify-center items-center text-center z-10 bg-gray-600 hover:bg-opacity-90 dark:hover:bg-opacity-90 bg-opacity-50 dark:bg-opacity-50 rounded-md">
        <button
          type="button"
          className="btn-close-relative flex justify-center items-center"
          onClick={() => {
            setFiles([]);
            onImageUploadUnselect && onImageUploadUnselect();
          }}
        >
          <i className="fal fa-times fill-white text-white hover:text-accent-light text-md px-2"></i>
        </button>
      </div>
      <img
        src={file.preview}
        className="cursor-pointer dark:border-jacarta-600 border-jacarta-100 border-1 object-cover w-full h-full rounded"
        onClick={open}
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />
    </div>
    // </div>
  ));

  return (
    <>
      <Controller
        control={control}
        name={form_name}
        rules={{ required: required }}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => (
          <>
            <div
              className={`group relative overflow-hidden rounded-md w-full flex flex-col items-center justify-center text-center ${heightClass}`}
            >
              {files.length > 0 ? (
                <aside className="flex flex-col items-center justify-center w-full h-full overflow-hidden rounded-md">
                  {thumbs}
                </aside>
              ) : (
                <>
                  <div
                    className="relative z-10 cursor-pointer"
                    onClick={() => {
                      open();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-jacarta-500 mb-4 inline-block dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M16 13l6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z" />
                    </svg>
                    <p className="dark:text-jacarta-300 mx-auto max-w-xs text-xs">
                      JPG, JPEG, PNG
                    </p>
                  </div>
                  <div
                    {...getRootProps({
                      className:
                        "dark:bg-jacarta-600 bg-jacarta-50 absolute inset-4 cursor-pointer rounded dark:border-1 border-1 border-jacarta-200 dark:border-jacarta-600 opacity-50 group-hover:opacity-100 dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                  </div>
                </>
              )}
            </div>
            {error && (
              <span className="text-red pr-2">
                {errorMessage}
              </span>
            )}
          </>
        )}
      />
    </>
  );
}
