export const ext_design_types_prompt_data = {
    "Gothic": ["medieval, pointed arches"],
    "Baroque": ["ornate, dramatic"],
    "Post-Modern": ["Playful, kitch, abstract, hyperreal, colourful"],
    "Artistic": ["colourful, art, paint, glass. metal, accents"],
    "Renaissance": ["symmetrical, classical"],
    "Art Deco": ["geometric, glamorous"],
    "Art Nouveau": ["organic, flowing"],
    "Victorian": ["eclectic, ornate"],
    "Bauhaus": ["functional, minimalist"],
    "Neo-Gothic": ["revivalist, pointed"],
    "Beaux-Arts": ["grand, classical"],
    "Brutalism": ["raw, monolithic, concrete"],
    "Colonial": ["symmetrical, formal"],
    "Modern": ["clean, uncluttered"],
    "Postmodern": ["eclectic, ironic"],
    "Futuristic": ["innovative, unconventional, shinny"],
    "Craftsman": ["handcrafted, simple"],
    "International Style": ["functional, minimal"],
    "Deconstructivism": ["fragmented, non-rectilinear"],
    "High-Tech Architecture": ["technological, transparent"],
    "Streamline Moderne": ["aerodynamic, nautical"],
    "Romanesque": ["arched, sturdy"],
    "Byzantine": ["ornamental, domed"],
    "Prairie Style": ["horizontal, natural"],
    "Mediterranean": ["warm, tiled roofs"],
    "Tudor": ["timbered, steep roofs"],
    "Georgian": ["elegant, proportioned"],
    "Palladian": ["classical, symmetrical"],
    "Federal": ["refined, decorative"],
    "Art Moderne": ["streamlined, curvy"],
    "Egyptian Revival": ["ancient motifs, monumental"],
    "Carpenter Gothic": ["wooden, intricate"],
    "Rococo": ["decorative, light"],
    "Aesthetic Movement": ["artistic, decorative"],
    "American Foursquare": ["simple, boxy"],
    "Italianate": ["ornate brackets, tall windows"],
    "Second Empire": ["mansard roof, grand"],
    "Stick Style": ["wooden stickwork, decorative trusses"],
    "Spanish Architecture": ["warm, earthy tones, stucco walls, terracotta roof tiles, ornate ironwork, rustic wood elements"],
    "Greco-Roman": ["columns, symmetrical"],
    "Scandinavian Modern": ["minimal, natural materials"],
    "Japanese Traditional": ["minimalist, natural"]
}

export const building_desctiption_data = {
    "Villa": ["luxury house, country estate"],
    "House": ["residence"],
    "Condominium": ["residential apartment, condo complex, multi-unit building"],
    "Cottage": ["small house, country home"],
    "Skyscraper": ["high-rise building, tower"],
    "Residential House": ["private dwelling, family home"],
    "Hospital": ["medical center, healthcare facility"],
    "School": ["educational institution, learning facility"],
    "University Campus": ["college grounds, academic complex"],
    "Museum": ["exhibition hall, gallery"],
    "Shopping Mall": ["shopping center, retail complex"],
    "Theater": ["playhouse, auditorium"],
    "Library": ["public library, book repository"],
    "Church": ["chapel, place of worship"],
    "Mosque": ["Islamic center, prayer hall"],
    "Synagogue": ["Jewish temple, house of prayer"],
    "Temple": ["religious site, sacred place"],
    "Factory": ["manufacturing plant, industrial facility"],
    "Warehouse": ["storage facility, distribution center"],
    "Sports Stadium": ["arena, athletic field"],
    "Airport Terminal": ["airfield building, flight center"],
    "Train Station": ["railway station, depot"],
    "Fire Station": ["firehouse, emergency services center"],
    "Courthouse": ["legal center, judicial building"],
    "Hotel": ["inn, lodging facility"],
    "Office Building": ["business center, corporate headquarters"],
    "Barn": ["farm shed, agricultural building"],
    "Restaurant": ["eatery, dining establishment"]
}

export const season_conditions = {
    "Spring": ["Spring, blossom, blooming, mild"],
    "Summer": ["Summer, warm, sunny, lively"],
    "Winter": ["Winter, cold, snowy, quiet"],
    "Fall": ["Fall, autumnal, crisp, colorful"]
}

export const weather_conditions = {
    "Rainy": ["Rainy Weather, wet, drizzly, refreshing, cloudy sky"],
    "Snowfall": ["Snowfall, white, tranquil, blanketing, cloudy sky"],
    "Sunny": ["Sunny, bright, cheerful, clear, clear sky"],
    "Windy": ["Windy, blustery, brisk, energetic, clear sky"],
    "Overcast": ["Overcast, cloudy, gray, soft light, cloudy sky"],
    "Stormy": ["Stormy Weather, turbulent, windy, electric, dark sky"],
    "Foggy": ["Foggy Morning, misty, obscure, mysterious"],
    "Clear": ["Clear sky, crisp, silent"]
}

export const time_of_the_day_conditions = {
    "Morning": ["Morning Light, soft, refreshing"],
    "Afternoon": ["Afternoon Light, bright, vivid, warm"],
    "Evening": ["Evening Light, dimming, amber, tranquil"],
    "Night": ["Night, night light, night sky"],
    "Dusk": ["Dusk, twilight, dusky"],
    "Dawn": ["Dawn, dawn light, serene, pale, new"],
    "Sunrise":["Sunrise, Morning Sun, sunrise light"],
    "Sunset": ["Sunset, Dusk, muted, cool, calming"],
    "Midday": ["Midday Sun, intense, high, radiant"],    
}

export const climate_conditions = {
    "Humid": ["Humid Climate, muggy, sticky, moist"],
    "Dry": ["Dry Climate, arid, parched, barren"],
    "Tropical": ["Tropical Climate, hot, lush, exotic"],
  }
  
  export const landscape_conditions = {
    "Mountainous": ["Mountainous Terrain, rugged, elevated, majestic"],
    "Coastal": ["Coastal Environment, salty, breezy, maritime, cliff"],
    "Urban": ["Urban Landscape, downtown, city, busy, constructed, dense"],
    "Rural": ["Rural Landscape, pastoral, peaceful, spread out"],
    "Forest": ["Forest Setting, wooded, green, tranquil, trees"],
    "Riverbank": ["Riverbank, flowing, serene, fertile"],
    "Lakeside": ["Lakeside, calm, reflective, still"],
    "Wetlands": ["Wetlands, marshy, lush, abundant wildlife"],
    "Prairie": ["Prairie, open, grassy, expansive"],
    "Canyon": ["Canyon, deep, rocky, dramatic"],
    "Desert": ["Desert Climate, hot, dry, sparse"],
    "Savanna": ["Savanna, grassy, open, wild"],
    "Rainforest": ["Rainforest, dense, humid, biodiverse"]
}


export const time_of_day_int_conditions = {
    "Morning":["Morning Light, dawn"],
    "Afternoon": ["afternoon Light, hazy"],
    "Evening":["evening light, twilight, soft, dusk,"],
    "Night":["Night, night sky"]
}


export const lighting_conditions = {
    "Bright": ["brightness, contrast, softlight, blend"],
    "Soft": ["dim light, soft light, soft shadows, low light, blend"],
    "Ambient":["ambient lighting, blend"],
    "Natural": ["natural light, soft light, exterior light, direct lighting, blend"],
    "Artificial": ["artificial light, direct light, cool, bland"],
    "Warm": ["warm light, soft light, warm, blend"],
    "Cool": ["cool light, blend"]
}


// export const design_types_prompt_data = {
//     "Modern": ["Sleek neutrals, white walls, and bold accent colors", "Neutral shades, light gray walls, and contrasting accent colors", ""],
//     "Contemporary": ["Monochromatic, gray walls, and vibrant accents", "Varied hues, white walls, and bold accents", ""],
//     "Minimalist": ["Neutral tones, white walls, and minimal color accents", "Subdued colors, off-white walls, and understated color accents", ""],
//     "Industrial": ["Dark grays, exposed brick walls, and metallic accents", "Earthy tones, raw concrete walls, and copper accents", ""],
//     "Mid-Century Modern": ["Earthy tones, white walls, and pops of bright colors", "Soft colors, gray walls, and vibrant accent colors", ""],
//     "Scandinavian": ["Cool grays, white walls, and soft pastel accents", "Light neutrals, soft gray walls, and subtle color accents", ""],
//     "Traditional": ["Warm neutrals, beige walls, and rich jewel-toned accents", "Rich colors, wallpapered walls, and classic accent colors", ""],
//     "Transitional": ["Sophisticated neutrals, light gray walls, and muted accent colors", "Elegant neutrals, warm gray walls, and delicate accent colors", ""],
//     "Bohemian": ["Vibrant, warm colors, white walls, and eclectic accents", "Varied, earthy colors, neutral walls, and lively accents", ""],
//     "Farmhouse": ["Whites, soft grays, and natural wood accents", "Soft neutrals, shiplap walls, and natural material accents", ""],
//     "Rustic": ["Earthy tones, textured walls, and warm wood accents", "Nature-inspired colors, stone walls, and reclaimed wood accents", ""],
//     "Coastal": ["Soft blues, white walls, and natural wood accents", "Bright whites, light blue walls, and sandy accents", ""],
//     "Shabby Chic": ["Whites, pastels, and distressed wood accents", "Faded pastels, distressed walls, and vintage accents", ""],
//     "French Country": ["Soft, muted colors, cream walls, and rustic wood accents", "Warm, subdued colors, aged walls, and weathered wood accents", ""],
//     "Art Deco": ["Bold, contrasting colors, black walls, and metallic accents", "Geometric patterns, gold walls, and black accents", ""],
//     "Hollywood Regency": ["Jewel tones, white walls, and gold or silver accents", "Bold colors, mirrored walls, and crystal accents", ""],
//     "Japanese Zen": ["Natural colors, light wood walls, and black accents", "Earthy hues, tatami walls, and natural material accents", ""],
//     "Mediterranean": ["Warm earth tones, textured walls, and colorful accents", "Sun-kissed colors, plaster walls, and handmade tile accents", ""],
//     "Victorian": ["Rich jewel tones, wallpapered walls, and gold or brass accents", "Deep, luxurious colors, velvet walls, and intricate accents", ""],
//     "Eclectic": ["Mix of bold colors, patterned walls, and varied accents", "Diverse color combinations, gallery walls, and unexpected accents", ""],
//     "Urban Modern": ["Neutral palette, concrete walls, and metallic accents", "Sophisticated colors, exposed walls, and geometric accents", ""],
//     "Classic Georgian": ["Soft, elegant colors, white walls, and refined accents", "Elegant pastels, paneled walls, and antique accents", ""],
//     "Bauhaus": ["Clean lines, white walls, and black, white, gray, primary red, primary blue, primary yellow, beige, mustard, steel blue, cream as accents", "Functional design, light walls, and charcoal, pure white, neutral gray, leather, deep blue, vivid yellow, sand, goldenrod, smoky blue, ecru as accent color", ""],
//     "Colonial": ["Classic neutrals, white walls, and dark wood accents", "Timeless colors, wainscoting walls, and polished wood accents", ""],
//     "Craftsman": ["Earthy tones, wood-paneled walls, and warm wood accents", "Nature-inspired hues, wood trim walls, and handcrafted accents", ""],
//     "Prairie Style": ["Natural colors, wood walls, and muted earthy accents", "Subdued colors, horizontal wood walls, and organic accents", ""],
//     "Art Nouveau": ["Flowing lines, muted walls, and organic color accents", "Curving lines, embellished walls, and nature-inspired color accents", ""],
//     "Gothic Revival": ["Dark, rich colors, stone walls, and ornate accents", "Intense colors, stained glass walls, and dramatic accents", ""],
//     "Brutalist": ["Monochromatic, raw concrete walls, and minimal accents", "Raw materials, gray concrete walls, and subtle color accents", ""],
//     "Greek Revival": ["Crisp whites, light walls, and classic blue accents", "Classic whites, columned walls, and marine blue accents", ""],
//     "Tuscan": ["Warm earth tones, textured walls, and terracotta accents", "Sunbaked colors, frescoed walls, and aged wood accents", ""],
//     "Spanish Revival": ["Warm, earthy colors, stucco walls, and wrought iron accents", "Muted earth tones, terracotta walls, and ceramic accents", ""],
//     "Baroque": ["Rich, opulent colors, ornate walls, and gilded accents", "Opulent, extravagant colors, painted walls, and silver accents", ""],
//     "Rococo": ["Pastel colors, ornate walls, and gold accents", "Delicate pastels, curved walls, and ornamental accents", ""],
//     "Neoclassical": ["Subdued palette, white walls, and classical ornament accents", "Muted tones, symmetrical walls, and antique accents", ""],
//     "English Country": ["Warm, cozy colors, floral walls, and antique wood accents", "Gentle, inviting colors, patterned walls, and comfortable accents", ""],
//     "Southwest": ["Warm earth tones, adobe walls, and vibrant textile accents", "Desert-inspired colors, plaster walls, and Native American textile accents", ""],
//     "Tropical": ["Bright, bold colors, white walls, and natural material accents", "Vibrant, island-inspired colors, airy walls, and lush greenery accents", ""],
//     "Asian Fusion": ["Rich, warm colors, white walls, and East Asian-inspired accents", "warm colors, silk-screened walls, and a blend of Eastern-inspired accents", ""],
//     "Japandi": ["Soft neutrals, light walls, and a mix of Japanese and Scandinavian accents", "Muted tones, minimalist walls, and a fusion of Japanese and Scandinavian accents", ""],
//     "Arabic": ["Rich jewel tones, patterned walls, and intricate metalwork accents", "Opulent colors, intricate walls, and gold or silver accents", ""],
//     "Japanese": ["Natural colors, minimalist walls, and clean-lined accents", "Earthy hues, shoji screen walls, and subtle color accents", ""],
//     "Persian": ["Vibrant, white patterned walls, luxurious elements, persian carpet, and ornate textile accents", "Deep, rich colors, white intricately decorated walls, and luxurious textile accents", ""],
//     "Chinese": ["Reds, golds, black lacquer walls, and traditional Chinese motifs", "Bold reds, gold accents, and intricate wood or lacquer walls", ""],
// }

export const design_types_prompt_data = {
    "Modern": ["Simple, Monochromatic Color Palette, white walls", "Neutral Tones, light gray walls, accent colors", ""],
    "Post-Modern": ["Playful, contradictory, kitsch, Abstract, hyperreal, bold, colourful", "theatrical, symbolic, abstract, kitch, colourful"],
    "Contemporary": ["Monochromatic, gray walls, and vibrant color", "Varied hues, white walls, and bold accents", ""],
    "Minimalist": ["Neutral tones, white walls, and minimal color accents", "Subdued colors, off-white walls, and understated color accents", ""],
    "Industrial": ["Dark grays, exposed brick walls, and metallic accents", "Earthy tones, raw concrete walls, and copper accents", ""],
    "Mid-Century Modern": ["Earthy tones, white walls, and pops of bright colors", "Soft colors, gray walls, and vibrant accent colors", ""],
    "Scandinavian": ["Minimalist, Natural, Cozy, Cool, soft Monochromatic colour palletes accents", "Light neutrals, soft gray walls, and subtle color accents", ""],
    "Artistic": ["Colourful, Art, Funky,", "colourful fixtures, cmyk, expressive colorful furniture, bold, unique" ],
    "Traditional": ["Warm neutrals, beige walls, and rich jewel-toned accents", "Rich colors, wallpapered walls, and classic accent colors", ""],
    "Transitional": ["Sophisticated neutrals, light gray walls, and muted accent colors", "Elegant neutrals, warm gray walls, and delicate accent colors", ""],
    "Bohemian": ["Vibrant, warm colors, white walls, and eclectic accents", "Varied, earthy colors, neutral walls, and lively accents", ""],
    "Farmhouse": ["Whites, soft grays, and natural wood accents", "Soft neutrals, shiplap walls, and natural material accents", ""],
    "Rustic": ["Earthy tones, textured walls, and warm wood accents", "Nature-inspired colors, stone walls, and reclaimed wood accents", ""],
    "Coastal": ["Soft blues, white walls, and natural wood accents", "Bright whites, light blue walls, and sandy accents", ""],
    "Shabby Chic": ["Whites, pastels, and distressed wood accents", "Faded pastels, distressed walls, and vintage accents", ""],
    "French Country": ["Soft, muted colors, cream walls, and rustic wood accents", "Warm, subdued colors, aged walls, and weathered wood accents", ""],
    "Art Deco": ["Bold, contrasting colors, black walls, and metallic accents", "Geometric patterns, gold walls, and black accents", ""],
    "Hollywood Regency": ["Jewel tones, white walls, and gold or silver accents", "Bold colors, mirrored walls, and crystal accents", ""],
    "Japanese Zen": ["Natural colors, light wood walls, and black accents", "Earthy hues, tatami walls, and natural material accents", ""],
    "Mediterranean": ["Warm earth tones, textured walls, and colorful accents", "Sun-kissed colors, plaster walls, and handmade tile accents", ""],
    "Victorian": ["Rich jewel tones, wallpapered walls, and gold or brass accents", "Deep, luxurious colors, velvet walls, and intricate accents", ""],
    "Eclectic": ["Mix of bold colors, patterned walls, and varied accents", "Diverse color combinations, gallery walls, and unexpected accents", ""],
    "Urban Modern": ["Neutral palette, concrete walls, and metallic accents", "Sophisticated colors, exposed walls, and geometric accents", ""],
    "Classic Georgian": ["Soft, elegant colors, white walls, and refined accents", "Elegant pastels, paneled walls, and antique accents", ""],
    "Bauhaus": ["Clean lines, white walls, and black, white, gray, primary red, primary blue, primary yellow, beige, mustard, steel blue, cream as accents", "Functional design, light walls, and charcoal, pure white, neutral gray, leather, deep blue, vivid yellow, sand, goldenrod, smoky blue, ecru as accent color", ""],
    "Colonial": ["Classic neutrals, white walls, and dark wood accents", "Timeless colors, wainscoting walls, and polished wood accents", ""],
    "Craftsman": ["Earthy tones, wood-paneled walls, and warm wood accents", "Nature-inspired hues, wood trim walls, and handcrafted accents", ""],
    "Prairie Style": ["Natural colors, wood walls, and muted earthy accents", "Subdued colors, horizontal wood walls, and organic accents", ""],
    "Art Nouveau": ["Flowing lines, muted walls, and organic color accents", "Curving lines, embellished walls, and nature-inspired color accents", ""],
    "Gothic Revival": ["Dark, rich colors, stone walls, and ornate accents", "Intense colors, stained glass walls, and dramatic accents", ""],
    "Brutalist": ["Monochromatic, raw concrete walls, and minimal accents", "Raw materials, gray concrete walls, and subtle color accents", ""],
    "Greek Revival": ["Crisp whites, light walls, and classic blue accents", "Classic whites, columned walls, and marine blue accents", ""],
    "Tuscan": ["Warm earth tones, textured walls, and terracotta accents", "Sunbaked colors, frescoed walls, and aged wood accents", ""],
    "Spanish Revival": ["Warm, earthy colors, stucco walls, and wrought iron accents", "Muted earth tones, terracotta walls, and ceramic accents", ""],
    "Baroque": ["Rich, opulent colors, ornate walls, and gilded accents", "Opulent, extravagant colors, painted walls, and silver accents", ""],
    "Rococo": ["Pastel colors, ornate walls, and gold accents", "Delicate pastels, curved walls, and ornamental accents", ""],
    "Neoclassical": ["Subdued palette, white walls, and classical ornament accents", "Muted tones, symmetrical walls, and antique accents", ""],
    "English Country": ["Warm, cozy colors, floral walls, and antique wood accents", "Gentle, inviting colors, patterned walls, and comfortable accents", ""],
    "Southwest": ["Warm earth tones, adobe walls, and vibrant textile accents", "Desert-inspired colors, plaster walls, and Native American textile accents", ""],
    "Tropical": ["Bright, bold colors, white walls, and natural material accents", "Vibrant, island-inspired colors, airy walls, and lush greenery accents", ""],
    "Asian Fusion": ["Rich, warm colors, white walls, and East Asian-inspired accents", "warm colors, silk-screened walls, and a blend of Eastern-inspired accents", ""],
    "Japandi": ["Soft neutrals, light walls, and a mix of Japanese and Scandinavian accents", "Muted tones, minimalist walls, and a fusion of Japanese and Scandinavian accents", ""],
    "Arabic": ["Rich jewel tones, patterned walls, and intricate metalwork accents", "Opulent colors, intricate walls, and gold or silver accents", ""],
    "Japanese": ["Natural colors, minimalist walls, and clean-lined accents", "Earthy hues, shoji screen walls, and subtle color accents", ""],
    "Persian": ["Vibrant, white patterned walls, luxurious elements, persian carpet, and ornate textile accents", "Deep, rich colors, white intricately decorated walls, and luxurious textile accents", ""],
    "Chinese": ["Reds, golds, black lacquer walls, and traditional Chinese motifs", "Bold reds, gold accents, and intricate wood or lacquer walls", ""],
}

// export const material_values = {
//     "Modern": ["Glass walls, light hardwood floors, metal accents, wood finishes", "Open floor plans, large windows, natural light, sleek surfaces"],
//     "Contemporary": ["Glass elements, metal accents, wood floors", "Clean lines, open spaces, natural materials, geometric shapes"],
//     "Minimalist": ["Natural light, wood floors, glass accents", "Simplicity, functional design, minimal decor"],
//     "Industrial": ["Exposed brick, metal beams, reclaimed wood", "Raw materials, exposed ducts, metal fixtures, warehouse elements"],
//     "Mid-Century Modern": ["Wood floors, geometric patterns, natural fibers, glass elements", "Organic shapes, vintage pieces, functional design"],
//     "Scandinavian": ["Light wood floors, clean lines, natural fibers", "Simplicity, functionality, cozy textures, light woods"],
//     "Traditional": ["Rich wood finishes, ornate details, upholstered furniture, patterned fabrics", "Classic furnishings, ornate details, rich woods, luxurious fabrics"],
//     "Transitional": ["Mixed textures, wood elements, glass accents", "Elegant design, classic lines, modern accents"],
//     "Bohemian": ["Eclectic patterns, natural fibers, mixed materials", "Layered textiles, eclectic collections, global accents"],
//     "Farmhouse": ["Reclaimed wood, distressed finishes, antique accents, natural textiles", "Rustic elements, vintage pieces, cozy textiles, warm woods"],
//     "Rustic": ["Wood beams, stone walls, natural fibers, wrought iron accents", "Reclaimed materials, organic shapes, natural textures"],
//     "Coastal": ["Driftwood, natural fibers, ocean-inspired accents", "Airy spaces, beach-inspired accents, crisp whites, soft blues"],
//     "Shabby Chic": ["Distressed wood, vintage details, floral patterns", "Vintage furniture, distressed finishes, feminine touches"],
//     "French Country": ["Ornate wood carvings, rustic finishes, natural fibers", "Rustic elegance, ornate details, warm colors, natural materials"],
//     "Art Deco": ["Geometric patterns, glossy finishes, exotic woods, metallic accents", "Bold patterns, metallic finishes, luxurious textiles, glamorous design"],
//     "Hollywood Regency": ["Mirrored surfaces, velvet upholstery, metal accents", "Opulent details, luxurious fabrics, statement pieces, bold patterns"],
//     "Japanese Zen": ["Minimal design, natural materials, sliding doors, tatami mats", "Serenity, balance, clean lines, natural elements"],
//     "Mediterranean": ["Terracotta tiles, stucco walls, wrought iron", "Textured walls, rich woods, ornate details"],
//     "Victorian": ["Ornate woodwork, patterned wallpapers, plush fabrics", "Detailed craftsmanship, elegant patterns, antique pieces, rich textiles"],
//     "Eclectic": ["Mixed patterns, varied textures, curated collections", "Diverse textures, unique items, global influences"],
//     "Urban Modern": ["Industrial elements, sleek lines, metal accents", "Loft-style spaces, exposed elements, minimalist design, sophisticated accents"],
//     "Classic Georgian": ["Symmetrical design, columns, ornate woodwork", "Timeless design, balanced proportions, elegant details, refined style"],
//     "Bauhaus": ["Minimalist design, geometric shapes, metal accents", "Functional design, simple lines, industrial materials"],
//     "Colonial": ["Traditional woodwork, brick elements, white exteriors, classic furnishings", "Symmetrical architecture, refined furnishings, elegant moldings, historic touches"],
//     "Craftsman": ["Wood finishes, natural materials, built-in furniture", "Handcrafted details, functional design, natural materials"],
//     "Prairie Style": ["Horizontal lines, low-pitched roofs, open spaces, natural materials", "Integrated design, open spaces, natural light, organic materials"],
//     "Art Nouveau": ["Organic shapes, intricate details, stained glass", "Nature-inspired motifs, sinuous lines, elegant craftsmanship, intricate details"],
//     "Gothic Revival": ["Pointed arches, ornate woodwork, stone details", "Vertical lines, pointed arches, stained glass, intricate carvings"],
//     "Brutalist": ["Raw concrete, geometric shapes, strong lines", "Bold geometry, exposed materials, monolithic forms, utilitarian design"],
//     "Greek Revival": ["Columns, symmetrical design, white exteriors, ornate details", "Classical architecture, elegant proportions, ornate details, timeless style"],
//     "Tuscan": ["Terracotta tiles, plaster walls, wooden beams", "Earthy tones, textured walls, warm woods, rustic charm"],
//     "Spanish Revival": ["Terracotta roofs, stucco walls, wrought iron, colorful tiles", "Arched doorways, warm stucco, handcrafted details"],
//     "Baroque": ["Ornate details, gilded accents, plush fabrics", "Grandeur, opulence, gilded accents"],
//     "Rococo": ["Curving shapes, ornate details, delicate fabrics", "Whimsical details, curving lines, ornate carvings"],
//     "Neoclassical": ["Symmetry, columns, refined details", "Classical motifs, refined elegance, balanced design, timeless beauty"],
//     "English Country": ["Antique furniture, floral patterns, wood accents", "Cozy spaces, antique pieces, floral patterns, layered textiles"],
//     "Southwest": ["Adobe walls, exposed wood beams, natural materials", "Earthy colors, indigenous materials, natural textures, regional influences"],
//     "Tropical": ["Rattan, bamboo, natural fibers", "Lush foliage, natural materials, open spaces"],
//     "Asian Fusion": ["Minimal design, natural materials, oriental accents", "Eastern influences, natural materials, minimalist design"],
//     "Japandi": ["Minimalist design, natural materials, clean lines", "Warm woods, minimalist aesthetics, natural textures"],
//     "Arabic": ["Geometric patterns, ornate details, rich textiles", "Ornate carvings, luxurious fabrics, intricate patterns"],
//     "Japanese": ["Tatami mats, sliding doors, natural materials, minimal design", "Minimalist spaces, natural materials, harmony with nature"],
//     "Persian": ["Intricate patterns, luxurious textiles, ornate details, persian carpet, white ceramic floor", "Textiles, ornate details, handcrafted artistry, persian carpet, ceramic floor"],
//     "Chinese": ["Lacquered furniture, silk textiles, intricate carvings", "Traditional motifs, lacquered surfaces, ornate carvings"],
//     "Traditional Landscape": ["Features symmetrical plantings, well-manicured lawns, and classic foundation plants, creating a timeless, polished look", ""],
//     "Low-Maintenance Xeriscaping": ["Utilizes drought-tolerant plants, gravel, or mulch to reduce water use and maintenance requirements", ""],
//     "Modern Minimalist": ["Emphasizes clean lines, geometric shapes, and a restrained plant palette for a contemporary, low-maintenance aesthetic", ""],
//     "Cottage Garden": ["Incorporates a mix of flowers, herbs, and shrubs, creating a casual, cozy, and colorful atmosphere", ""],
//     "Edible Garden": ["Combines fruit trees, vegetables, and herbs with ornamental plants to create a practical and visually appealing space", ""],
//     "Formal Garden": ["Characterized by symmetrical layouts, clipped hedges, and sculptural elements that create a sense of order and elegance", ""],
//     "Japanese Garden": ["Utilizes stone, water, and plants to create a tranquil, natural environment that promotes mindfulness and reflection", ""],
//     "Mediterranean Garden": ["Features drought-tolerant plants, terracotta pots, and natural stone elements that evoke the warm, sunny climate of the Mediterranean region", ""],
//     "Native Plant Garden": ["Prioritizes the use of indigenous plants to support local ecosystems, promote biodiversity, and reduce maintenance", ""],
//     "Rock Garden": ["Incorporates rocks, boulders, and alpine plants to create an attractive, low-maintenance landscape", ""],
//     "Outdoor Living Space": ["Combines comfortable seating, shade structures, and lighting to create an inviting area for relaxation and socializing", ""],
//     "Family-Friendly Lawn": ["Offers a lush, durable lawn for play, relaxation, and entertaining, with space for playground equipment or sports", ""],
//     "Swimming Pool and Patio": ["Integrates a swimming pool with a surrounding patio for sunbathing, entertaining, and outdoor dining", ""],
//     "Vegetable and Herb Garden": ["Provides a dedicated area for growing fresh produce and herbs for cooking and enjoyment", ""],
//     "Zen Garden": ["Features minimal plantings, raked gravel or sand, and rock formations to create a serene, meditative space", ""],
//     "Outdoor Kitchen and Dining Area": ["Equips an outdoor cooking area with appliances, seating, and a dining table for al fresco meals and entertaining", ""],
//     "Wildlife Habitat": ["Incorporates native plants, water features, and birdhouses to attract and support local wildlife", ""],
//     "Meditation and Yoga Space": ["Offers a secluded, peaceful area with comfortable surfaces and natural elements for relaxation and contemplation", ""],
//     "Fire Pit and Entertainment Area": ["Centers around a fire pit or fireplace, with ample seating for gathering and socializing", ""],
//     "English Country Garden": ["Combines flowering plants, hedges, and pathways to create a lush, romantic, and picturesque outdoor space", ""],
// }

export const material_values = {
    "Modern": ["Glass walls, light hardwood floors, white walls,", "Metal, shinny, white, clean lines", "Concrete, greys, greyscales, smooth finish", "Metal, shinny, white, clean lines", "Concrete, metal, birch wood, glass, white"],
    "Post-Modern": ["Metal, Reflective, glass, textiles", "theatrical, symbolic, abstract, kitch, colourful"],
    "Contemporary": ["Glass, metal, wood, colour, pastels,", "light wood, soft, textiles", "copper, green, textiles, soft, plush"],
    "Minimalist": ["wood floors, glass accents, minimal decor", "Simplicity, functional design, minimal decor"],
    "Industrial": ["Exposed brick, metal beams, reclaimed wood", "Raw materials, exposed ducts, metal fixtures, warehouse elements"],
    "Mid-Century Modern": ["Wood floors, geometric patterns, natural fibers, glass elements", "Organic shapes, vintage pieces, functional design"],
    "Scandinavian": ["Light wood, clean lines, natural fibers", "Simplicity, functionality, cozy textures, light woods"],
    "Traditional": ["Rich wood finishes, ornate details, upholstered furniture, patterned fabrics", "Classic furnishings, ornate details, rich woods, luxurious fabrics"],
    "Artistic": ["Colourful Paint, Canvas, Shinny Metals,", "pastels, cloth, fixtures, cmyk, expressive colorful furniture, bold, unique" ],
    "Transitional": ["Mixed textures, wood elements, glass accents", "Elegant design, classic lines, modern accents"],
    "Bohemian": ["Eclectic patterns, natural fibers, mixed materials", "Layered textiles, eclectic collections, global accents"],
    "Farmhouse": ["Reclaimed wood, distressed finishes, antique accents, natural textiles", "Rustic elements, vintage pieces, cozy textiles, warm woods"],
    "Rustic": ["Wood beams, stone walls, natural fibers, wrought iron accents", "Reclaimed materials, organic shapes, natural textures"],
    "Coastal": ["Driftwood, natural fibers, ocean-inspired accents", "Airy spaces, beach-inspired accents, crisp whites, soft blues"],
    "Shabby Chic": ["Distressed wood, vintage details, floral patterns", "Vintage furniture, distressed finishes, feminine touches"],
    "French Country": ["Ornate wood carvings, rustic finishes, natural fibers", "Rustic elegance, ornate details, warm colors, natural materials"],
    "Art Deco": ["Geometric patterns, glossy finishes, exotic woods, metallic accents", "Bold patterns, metallic finishes, luxurious textiles, glamorous design"],
    "Hollywood Regency": ["Mirrored surfaces, velvet upholstery, metal accents", "Opulent details, luxurious fabrics, statement pieces, bold patterns"],
    "Japanese Zen": ["Minimal design, natural materials, sliding doors, tatami mats", "Serenity, balance, clean lines, natural elements"],
    "Mediterranean": ["Terracotta tiles, stucco walls, wrought iron", "Textured walls, rich woods, ornate details"],
    "Victorian": ["Ornate woodwork, patterned wallpapers, plush fabrics", "Detailed craftsmanship, elegant patterns, antique pieces, rich textiles"],
    "Eclectic": ["Mixed patterns, varied textures, curated collections", "Diverse textures, unique items, global influences"],
    "Urban Modern": ["Industrial elements, sleek lines, metal accents", "Loft-style spaces, exposed elements, minimalist design, sophisticated accents"],
    "Classic Georgian": ["Symmetrical design, columns, ornate woodwork", "Timeless design, balanced proportions, elegant details, refined style"],
    "Bauhaus": ["Minimalist design, geometric shapes, metal accents", "Functional design, simple lines, industrial materials"],
    "Colonial": ["Traditional woodwork, brick elements, white exteriors, classic furnishings", "Symmetrical architecture, refined furnishings, elegant moldings, historic touches"],
    "Craftsman": ["Wood finishes, natural materials, built-in furniture", "Handcrafted details, functional design, natural materials"],
    "Prairie Style": ["Horizontal lines, low-pitched roofs, open spaces, natural materials", "Integrated design, open spaces, organic materials"],
    "Art Nouveau": ["Organic shapes, intricate details, stained glass", "Nature-inspired motifs, sinuous lines, elegant craftsmanship, intricate details"],
    "Gothic Revival": ["Pointed arches, ornate woodwork, stone details", "Vertical lines, pointed arches, stained glass, intricate carvings"],
    "Brutalist": ["Raw concrete, geometric shapes, strong lines", "Bold geometry, exposed materials, monolithic forms, utilitarian design"],
    "Greek Revival": ["Columns, symmetrical design, white exteriors, ornate details", "Classical architecture, elegant proportions, ornate details, timeless style"],
    "Tuscan": ["Terracotta tiles, plaster walls, wooden beams", "Earthy tones, textured walls, warm woods, rustic charm"],
    "Spanish Revival": ["Terracotta roofs, stucco walls, wrought iron, colorful tiles", "Arched doorways, warm stucco, handcrafted details"],
    "Baroque": ["Ornate details, gilded accents, plush fabrics", "Grandeur, opulence, gilded accents"],
    "Rococo": ["Curving shapes, ornate details, delicate fabrics", "Whimsical details, curving lines, ornate carvings"],
    "Neoclassical": ["Symmetry, columns, refined details", "Classical motifs, refined elegance, balanced design, timeless beauty"],
    "English Country": ["Antique furniture, floral patterns, wood accents", "Cozy spaces, antique pieces, floral patterns, layered textiles"],
    "Southwest": ["Adobe walls, exposed wood beams, natural materials", "Earthy colors, indigenous materials, natural textures, regional influences"],
    "Tropical": ["Rattan, bamboo, natural fibers", "Lush foliage, natural materials, open spaces"],
    "Asian Fusion": ["Minimal design, natural materials, oriental accents", "Eastern influences, natural materials, minimalist design"],
    "Japandi": ["Minimalist design, natural materials, clean lines", "Warm woods, minimalist aesthetics, natural textures"],
    "Arabic": ["Geometric patterns, ornate details, rich textiles", "Ornate carvings, luxurious fabrics, intricate patterns"],
    "Japanese": ["Tatami mats, sliding doors, natural materials, minimal design", "Minimalist spaces, natural materials, harmony with nature"],
    "Persian": ["Intricate patterns, luxurious textiles, ornate details, persian carpet, white ceramic floor", "Textiles, ornate details, handcrafted artistry, persian carpet, ceramic floor"],
    "Chinese": ["Lacquered furniture, silk textiles, intricate carvings", "Traditional motifs, lacquered surfaces, ornate carvings"],
    "Traditional Landscape": ["Features symmetrical plantings, well-manicured lawns, and classic foundation plants, creating a timeless, polished look", ""],
    "Low-Maintenance Xeriscaping": ["Utilizes drought-tolerant plants, gravel, or mulch to reduce water use and maintenance requirements", ""],
    "Modern Minimalist": ["Emphasizes clean lines, geometric shapes, and a restrained plant palette for a contemporary, low-maintenance aesthetic", ""],
    "Cottage Garden": ["Incorporates a mix of flowers, herbs, and shrubs, creating a casual, cozy, and colorful atmosphere", ""],
    "Edible Garden": ["Combines fruit trees, vegetables, and herbs with ornamental plants to create a practical and visually appealing space", ""],
    "Formal Garden": ["Characterized by symmetrical layouts, clipped hedges, and sculptural elements that create a sense of order and elegance", ""],
    "Japanese Garden": ["Utilizes stone, water, and plants to create a tranquil, natural environment that promotes mindfulness and reflection", ""],
    "Mediterranean Garden": ["Features drought-tolerant plants, terracotta pots, and natural stone elements that evoke the warm, sunny climate of the Mediterranean region", ""],
    "Native Plant Garden": ["Prioritizes the use of indigenous plants to support local ecosystems, promote biodiversity, and reduce maintenance", ""],
    "Rock Garden": ["Incorporates rocks, boulders, and alpine plants to create an attractive, low-maintenance landscape", ""],
    "Outdoor Living Space": ["Combines comfortable seating, shade structures, and lighting to create an inviting area for relaxation and socializing", ""],
    "Family-Friendly Lawn": ["Offers a lush, durable lawn for play, relaxation, and entertaining, with space for playground equipment or sports", ""],
    "Swimming Pool and Patio": ["Integrates a swimming pool with a surrounding patio for sunbathing, entertaining, and outdoor dining", ""],
    "Vegetable and Herb Garden": ["Provides a dedicated area for growing fresh produce and herbs for cooking and enjoyment", ""],
    "Zen Garden": ["Features minimal plantings, raked gravel or sand, and rock formations to create a serene, meditative space", ""],
    "Outdoor Kitchen and Dining Area": ["Equips an outdoor cooking area with appliances, seating, and a dining table for al fresco meals and entertaining", ""],
    "Wildlife Habitat": ["Incorporates native plants, water features, and birdhouses to attract and support local wildlife", ""],
    "Meditation and Yoga Space": ["Offers a secluded, peaceful area with comfortable surfaces and natural elements for relaxation and contemplation", ""],
    "Fire Pit and Entertainment Area": ["Centers around a fire pit or fireplace, with ample seating for gathering and socializing", ""],
    "English Country Garden": ["Combines flowering plants, hedges, and pathways to create a lush, romantic, and picturesque outdoor space", ""],
}

export const room_description_data = {
    "Living Room": ["sofa, coffee table, television, rug, armchair, curtains, bookshelf", "couch, end table, TV, area rug, recliner, window treatments, shelves"],
    "Bedroom": ["bed, nightstand, dresser, lamp, closet, curtains, mirror", "mattress, bedside table, chest of drawers, light, wardrobe, blinds, vanity"],
    "Kitchen": ["stove, refrigerator, sink, dishwasher, cabinets, microwave, countertop", "oven, fridge, basin, kitchen appliances, cupboards, toaster, island"],
    "Bathroom": ["toilet, sink, bathtub, shower, mirror, towel rack, cabinet", "lavatory, washbasin, tub, shower stall, vanity mirror, towel holder, storage unit"],
    "Home Office": ["desk, chair, computer, bookshelf, printer, filing cabinet, lamp", "workspace, seat, PC, shelving, scanner, document organizer, light fixture"],
    "Dining Room": ["dining table, chairs, sideboard, chandelier, rug, mirror, china cabinet", "eating table, seatings, buffet, pendant light, floor covering, wall mirror, curio cabinet"],
    "Children's Room": ["bed, toys, bookshelf, desk, chair, rug, dresser", "sleeping area, playthings, shelf unit, worktable, seating, floor covering, storage"],
    "Cafe": ["tables, chairs, counter, espresso machine, menu board, pastry display, barista", "seatings, furnitures, serving area, coffee maker, chalkboard menu, dessert showcase, coffee server"],
    "Restaurant": ["tables, chairs, bar, menu, waiter, kitchen, plates", "dining set, seats, counter, food list, waitstaff, cooking area, tableware"],
    "Bar": ["bar counter, bar stools, bartender, drinks, menu, tables, chairs", "bar top, high chairs, mixologist, beverages, listing, seatings, furnishings"],
    "Hotel Lobby": ["reception desk, seating area, luggage cart, elevator, plants, concierge", "front desk, lounge, bell cart, lift, greenery, guest services"],
    "Home Theater": ["screen, projector, seating, sound system, carpet, curtains, popcorn machine", "projection, projector, seats, audio system, carpeting, drapes, snack station"],
    "Library": ["bookshelves, reading chairs, tables, librarian desk, computers, lamps", "book storage, comfortable chairs, worktables, librarian station, workstations, lighting"],
    "Retail Store": ["shelves, clothing racks, cash register, dressing rooms, display windows, shopping bags", "display units, apparel stands, cash counter, fitting rooms, storefront, carry bags"],
    "Art Gallery": ["paintings, sculptures, art installations, benches, curator, gallery attendant", "artworks, statues, artistic displays, seating, gallery supervisor, attendant"],
    "Gym": ["treadmills, weights, exercise machines, mirrors, mats, water fountain, locker room", "running machines, dumbbells, workout devices, reflective surfaces, floor mats, water dispenser, changing area"],
    "Spa": ["massage tables, saunas, hot tubs, steam room, towels, robes, locker room", "treatment tables, sauna rooms, whirlpools, steam chambers, linens, dressing gowns, locker facilities"],
    "Game Room": ["pool table, foosball table, arcade machines, darts, poker table, television, seating", "billiards table, table soccer, game machines, dartboard, card table, TV, couches"],
    "Wine Cellar": ["wine racks, wine bottles, tasting table, glasses, temperature control, barrel", "wine storage, wine collection, sample table, stemware, climate control, cask"],
    "Studio Apartment": ["bed, sofa, kitchenette, table, chairs, bathroom, closet", "sleeping space, settee, small kitchen, dining set, washroom, storage room"],
    "Loft": ["open floor plan, exposed brick, high ceilings, windows, living area, bedroom", "open layout, exposed walls, tall ceilings, glass windows, living section, sleeping space"],
    "Sunroom": ["windows, seating, plants, side table, area rug, curtains, ceiling fan", "glass windows, chairs, greenery, occasional table, carpet, window coverings, fan"],
    "Mudroom": ["bench, coat hooks, shoe storage, rug, cubbies, umbrella stand, door", "seat, coat hangers, footwear organizer, floor mat, storage sections, rain gear stand, entrance"],
    "Laundry Room": ["washer, dryer, sink, ironing board, laundry basket, cabinets, shelves", "washing machine, drying machine, basin, iron stand, clothes hamper, cabinetry, racking"],
    "Garage": ["car, workbench, tools, storage shelves, bicycle, lawn equipment", "vehicle, work area, implements, storage racks, bike, yard tools"],
    "Basement": ["storage shelves, boxes, water heater, furnace, exercise equipment", "storage racks, containers, water heater unit, heating system, workout gear"],
    "Rooftop Lounge": ["seating, tables, bar, plants, lighting, view, umbrellas", "furniture, tabletops, refreshment area, flora, illumination, panorama, shade"],
    "Greenhouse": ["plants, shelves, potting bench, watering cans, tools, temperature control", "vegetation, storage, potting table, water containers, implements, climate management"],
    "Hair Salon": ["styling chairs, mirrors, shampoo stations, hair dryers, reception desk, products", "hair stations, reflective surfaces, washing spots, blow dryers, check-in desk, merchandise"],
    "Airport Lounge": ["seating, tables, charging stations, TV, bar, food, reception", "chairs, surfaces, power points, television, refreshment counter, edibles, reception"],
    "School Classroom": ["desks, chairs, blackboard, teacher's desk, computer, projector, books", "school desks, seats, writing surface, educator's workstation, computing"],
    "Yoga Studio": ["mats, instructor, blocks, straps, floor-to-ceiling mirrors, candles", "yoga mats, blocks, straps, mirrors, calm lighting, floor cushions, music system"],
    "Hospital Room": ["hospital bed, medical equipment, IV stand, chair, table, privacy curtain", "hospital bed, patient monitor, IV stand, nurse call button, side table, visitor chairs, curtains"],
    "Movie Theater": ["projection screen, cinema seats, projector, audio system, aisle lights, emergency exits, draperies", "screen, theater seating, projection booth, surround sound system, exit signs, aisle lighting, curtains"],
    "Train Station Waiting Room": ["seating, ticket machines, timetable, announcements, vending machines, trash cans", "chairs, ticket dispensers, schedule, public address system, snack machines, waste bins"],
    "Bank": ["teller windows, ATM, waiting area, security guard, vault, deposit slips", "transaction windows, cash machine, waiting space, security personnel, safe, transaction forms"],
    "Conference Room": ["table, chairs, projector, screen, whiteboard, speakerphone, outlets", "boardroom table, seats, video projector, display, dry-erase board, conference phone, power outlets"],
    "Co-working Space": ["desks, chairs, private offices, conference rooms, kitchen, printers, lounge area", "workstations, chairs, private rooms, meeting spaces, pantry, copy machines, relaxation zone"],
    "Dance Studio": ["mirrors, dance floor, barre, sound system, benches, cubbies", "wall mirrors, dancing surface, ballet rail, audio equipment, benches, storage spaces"],
    "Bakery": ["display cases, pastries, cash register, tables, chairs, kitchen, menu board", "display cabinets, baked goods, register, dining furniture, seating, preparation area, menu display"],
    "Nursery": ["crib, changing table, rocking chair, dresser, toys, mobile, rug", "baby cot, diaper changing station, glider, storage furniture, playthings, ceiling mobile, carpet"],
    "Guest Bedroom": ["bed, nightstand, dresser, lamp, closet, curtains, mirror", "sleeping furniture, side table, storage furniture, lighting fixture, clothes storage, drapes, looking glass"],
    "Home Bar": ["bar counter, bar stools, liquor, glassware, wine rack, refrigerator, shelves", "counter area, high seating, spirits, drinkware, wine holder, mini fridge, shelving"],
    "Walk-in Closet": ["clothing racks, shelves, drawers, mirror, island, seating, shoe storage", "clothing storage, shelf units, compartments, full-length mirror, closet island, seating, shoe organizer"],
    "Reception Area": ["reception desk, seating, coffee table, magazines, plants, telephone", "reception counter, lounge furniture, coffee table, periodicals, indoor plants, phone"],
    "Recording Studio": ["microphones, soundproof walls, mixing console, instruments, headphones, speakers", "mics, sound-absorbing walls, audio board, musical instruments, earphones, loudspeakers"],
    "Meditation Room": ["cushions, floor mats, candles, incense, quiet, plants, altar", "pillows, floor padding, aromatic candles, incense burner, tranquility, greenery, shrine"],
    "Food Court": ["tables, chairs, various food vendors, trash receptacles, menu boards, condiment stations", "dining tables, seats, diverse food outlets, garbage cans, menu displays, condiment area"],
    "Fast Food Restaurant": ["tables, chairs, counter, menu board, cash register, kitchen, drive-thru", "dining furniture, service counter, menu display, cash register, food preparation area, drive-thru service"],
    "Nightclub": ["dance floor, DJ booth, bar, seating, strobe lights, coat check, bouncers", "dancing zone, music booth, beverage station, couches, flashing lights, coat check area, security staff"],
    "Outdoor Patio": ["patio furniture, umbrella, grill, plants, lighting, fence, garden", "patio furnishings, parasol, barbecue, plants, lighting fixtures, fencing, landscaped area"],
    "Boutique": ["clothing racks, shelves, display window, dressing rooms, cash register, decor", "garment racks, shelf systems, shop window, changing rooms, checkout area, decorations"],
    "Bookstore": ["bookshelves, reading nooks, cashier, bestseller displays, tables, chairs", "literature shelves, cozy corners, cashier area, bestseller showcases, table surfaces, seating"],
    "Pharmacy": ["shelves, pharmacy counter, cash register, medications, health products, shopping carts", "product racks, prescription counter, cash register, medication, wellness items, shopping carts"],
    "Waiting Room": ["seating, reception desk, magazines, water cooler, television, plants", "seating, front desk, reading materials, water dispenser, TV set, potted plants"],
    "Fitness Center": ["exercise machines, weights, cardio equipment, lockers, mirrors, mats", "workout machines, free weights, aerobic equipment, lockers, wall mirrors, exercise mats"],
    "Garden Shed": ["tools, shelves, lawn mower, potting soil, watering cans, planters", "garden tools, shelving, lawnmower, planting soil, watering tools, containers"],
    "Pool House": ["changing rooms, pool, lounge chairs, towels, snack bar, shower", "changing facilities, swimming pool, sun loungers, towels, snack area, rinse off station"],
    "Workshop": ["workbench, tools, saws, drills, lumber, vise, storage shelves", "worktable, toolset, sawing tools, power drills, wood materials, clamp, storage racks"],
    "College Dorm Room": ["bed, desk, chair, wardrobe, mini fridge, microwave, bookshelf", "sleeping furniture, study area, chair, clothing storage, compact fridge, microwave oven, shelving"],
    "Office": ["desk, chair, computer, phone, file cabinet, office supplies, trash can", "workstation, seat, electronic device, telephone, filing unit, office items, garbage container"],
    "Playroom": ["toys, storage bins, seating, play mat, art supplies, chalkboard, bookshelf", "play items, organization containers, sitting options, playmat, creative materials, blackboard, shelving unit"],
    "Front Lawn": ["grass, landscaping, greenery, stepping stones, flowering plants, hedges, decorative rocks", "turf, pathway, garden beds, ornamental plants, lawn ornaments, driveway, streetlamp"],
    "Backyard": ["lush green grass, vibrant flower beds, winding pathways, tranquil water feature, cozy patio, enchanting pergola, tall privacy hedges", "verdant lawn, colorful blossoms, meandering garden path, soothing koi pond, inviting outdoor seating, charming gazebo, serene privacy fencing"]
}


export const design_types_color_palette_prompt_data = {
  Minimalist: {
    starting_prompt: "", // 'white, light, taupe, wood tone'
  },
};

export const room_types_prompt_data = {};

export const model_types_prompt_data = {
  "AI Navigator": {
    starting_prompt: "dreamlikeart",
  },
  "AI Sage": {
    starting_prompt: "photo",
  },
};

import { ComponentTypes } from "../../utils/enums";

export const modeling_techniques = {
  0: "IMG_TO_IMG",
  1: "CNET_CANNY",
  2: "CNET_MLSD_DEPTH",
  3: "IN_PAINTING",
  4: "CNET_HED",
  5: "CNET_MLSD",
  6: "IMG_TO_IMG_SDXL",
  7: "UPSCALE",
  8: "CNET_SDXL_CANNY",
  // 3: 'CNET_CANNY_IMG',
  // 4: 'CNET_HED_IMG',
};

export const modeling_name_to_no = {
  IMG_TO_IMG: "0",
  CNET_CANNY: "1",
  CNET_MLSD_DEPTH: "2",
  IN_PAINTING: "3",
  CNET_HED: "4",
  CNET_MLSD: "5",
  IMG_TO_IMG_SDXL: "6",
  UPSCALE: "7",
  CNET_SDXL_CANNY: "8",
  // 3: 'CNET_CANNY_IMG',
  // 4: 'CNET_HED_IMG',
};
