import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export default function Tabs(props) {
    const { tabs, index, setIndex, tippyMessage } = props;
    
    return (
        <div className="tabs">
            <div className="no-scrollbar nav nav-tabs scrollbar-custom flex items-center justify-start overflow-x-auto overflow-y-hidden pb-px">
                {
                    tabs.map((tab, i) => {
                        return (
                            tippyMessage ?
                                <Tippy key={i} interactive={true} interactiveBorder={20} delay={100} content={tippyMessage}>
                                    <div
                                        className="nav-item"
                                        onClick={() => {
                                            setIndex(i);
                                        }}>
                                        <span
                                            className={
                                                index === i
                                                    ? "cursor-pointer nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-3 dark:hover:text-white"
                                                    : "cursor-pointer nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-3 dark:hover:text-white"
                                            }>
                                            <span className="font-display text-sm font-medium">
                                                {tab.name}
                                            </span>
                                        </span>
                                    </div>
                                </Tippy> :
                                <div
                                    key={i}
                                    className="nav-item"
                                    onClick={() => {
                                        setIndex(i);
                                    }}>
                                    <span
                                        className={
                                            index === i
                                                ? "cursor-pointer nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-3 dark:hover:text-white"
                                                : "cursor-pointer nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-3 dark:hover:text-white"
                                        }>
                                        <span className="font-display text-sm font-medium">
                                            {tab.name}
                                        </span>
                                    </span>
                                </div>)
                    })
                }
            </div>
        </div>
    )
}
