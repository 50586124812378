import React, { useState, useEffect, useRef } from "react";
import { HexColorPicker } from "react-colorful";

export default function ColorPicker(props) {
    const { colorIndex, colorInit, setCustomerColor } = props;
    function handleClick() {
        setDisplayColorPicker((prev) => !prev)
    };

    function handleClose() {
        setDisplayColorPicker(false);
    };

    const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
    const [color, setColor] = React.useState(colorInit);

    useEffect(() => {
        setColor(colorInit);
    }, [colorInit])

    useEffect(() => {
        setCustomerColor(colorIndex, color);
    }, [color, setColor])

    return (
        <div className='w-full h-full relative'>
            {displayColorPicker ? <div className={`absolute bottom-0 z-10 ${colorIndex < 2? 'left-0' : 'right-0'}`}>
                <div className="fixed top-0 right-0 bottom-0 left-0" onClick={handleClose} />
                <div className="z-20 relative w-40 h-40 select-none">
                    <HexColorPicker color={color} onChange={setColor} />
                    <input type="text" placeholder="E.g. #8358FF" value={color} onChange={(e) => setColor(e.target.value)} className="dark:bg-jacarta-800 bg-white w-full border dark:border-jacarta-500 border-jacarta-300 rounded-b-md text-sm" />
                </div>
            </div> : null}
            <div className="inline-block cursor-pointer w-full h-full" onClick={handleClick}>
                <div className="w-full h-full rounded-md" style={{ backgroundColor: `${color}` }} />
            </div>
        </div>
    )
}
