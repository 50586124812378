import React, { useState, useEffect, useRef, use } from "react";
import Accordion from "../../modal/accordion";
import ConditionNode from "./conditions";
import {
  CoreDesignPosition,
  SpaceType,
  ConditionTypes,
} from "../../../utils/enums";
import {
  design_types_data,
  room_types_data,
  condition_keys
} from "../../../data/model_configs/interface_data";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

export default function ConditionsParent(props) {
    const {
        panelPosition,
        conditionValue,
        setConditionValue,
        spaceType
      } = props;

  const [defaultOpen, setDefaultOpen] = useState(true);

  function getStyleTitle() {
    return (
      <div className="flex w-fit justify-start flex-col">
        <div className="flex w-fit justify-start">
          Other Conditions
          <Tippy
            interactive={true}
            interactiveBorder={20}
            delay={100}
            content={
              <div className="px-5 py-3">
                <ul>
                  <li className="normal-case">
                    You can enrich your design by selecting the following styles
                  </li>
                </ul>
              </div>
            }
          >
            <span className="text-xs bg-jacarta-600 cursor-pointer rounded-full ml-2 p-2 w-5 h-5 text-jacarta-100 dark:text-jacarta-200 flex justify-center items-center">
              <b>?</b>
            </span>
          </Tippy>
        </div>
      </div>
    );
  }

  return (
    <>
      <Accordion title={getStyleTitle()} bordered={true} defaultOpen={defaultOpen} dynamicEvent={spaceType}>
        <div
          className={`relative flex flex-col justify-center`}
        >
          <div className={`w-full py-2 ${panelPosition && panelPosition === CoreDesignPosition.SIDE? "flex justify-center flex-col " : "grid grid-cols-1 md:grid-cols-2 md:gap-2"}`}>
            {
                condition_keys[spaceType].map((condition_key) => (
                    <div className="w-full py-3" key={condition_key}>
                        <ConditionNode
                            conditionType={condition_key}
                            conditionValue={conditionValue}
                            setConditionValue={setConditionValue}
                        />
                    </div>
                ))
            }
          </div>
        </div>
      </Accordion>
    </>
  );
}
